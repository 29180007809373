const getFirstTitle = hit => {
  if (hit.titleEn) {
    return 'titleEn';
  }
  if (hit.titleRu) {
    return 'titleRu';
  }
  return 'titleKk';
};

const getFirstDescription = hit => {
  if (hit.descriptionEn) {
    return 'descriptionEn';
  }
  if (hit.descriptionRu) {
    return 'descriptionRu';
  }
  return 'descriptionKk';
};

export const getTranslatedTitle = (currentLocale, hit) => {
  if (hit[`title${currentLocale}`]) {
    return `title${currentLocale}`;
  }
  if (hit.titleOthers && hit.titleOthers.length > 0) {
    return 'titleOthers[0]';
  }
  return getFirstTitle(hit);
};
export const getTranslatedDescription = (currentLocale, hit) => {
  if (hit[`description${currentLocale}`]) {
    return `description${currentLocale}`;
  }
  if (hit.descriptionOthers && hit.descriptionOthers.length > 0) {
    return 'descriptionOthers[0]';
  }
  return getFirstDescription(hit);
};
