import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaginatedQuery, useQuery } from 'react-query';
import { AppContext } from '../../App';
import LoadingComp from '../common/LoadingComp';
import NavigateSiteButton from '../common/navigateSiteButton/NavigateSiteButton';
import SocialMediaShareButton from '../common/shareButtons/SocialMediaShareButtons';
import VisitLinkButton from '../common/visitLinkButton/VisitLinkButton';
import { getTranslatedAttrib } from '../resourceDetails/ResourceDetailsToShare';
import getUserResources from '../../api/getUserResources';
import getCurrentUser from '../../api/getCurrentUser';

const initialState = {
  page: 1,
  limit: 10,
  nextToken: null,
};

const UserResources = () => {
  const [
    {
      localeState: { upperCaseLocale, lowerCaseLocale },
      currentCognitoUser,
    },
  ] = useContext(AppContext);

  const { t } = useTranslation();

  const [params, setParams] = useState({
    ...initialState,
  });

  const { isLoading: isCurrentUserLoading, data: currentUser } = useQuery(
    ['getCurrentUser', currentCognitoUser.username],
    getCurrentUser,
    { enabled: currentCognitoUser.username }
  );

  const { isLoading, resolvedData } = usePaginatedQuery(
    [
      'getUserResources',
      { ...params, resourceContributorId: currentUser && currentUser.id },
    ],
    getUserResources,
    { enabled: currentUser }
  );

  if (isLoading || isCurrentUserLoading) {
    return <LoadingComp style={{ marginTop: '-2rem' }} />;
  }

  if (resolvedData.items.length === 0) {
    return <div>{t('You have no resources in this category')}</div>;
  }

  return resolvedData.items.map(resource => {
    return (
      <div key={resource.id} className="MineItems__resource-container">
        <h4>
          {getTranslatedAttrib(resource.titles, 'title', upperCaseLocale)}
        </h4>

        {resource.descriptions.length > 0 && (
          <p>
            {getTranslatedAttrib(
              resource.descriptions,
              'description',
              upperCaseLocale
            )}
          </p>
        )}
        <div className="MineItems__pre-footer" style={{ fontWeight: '600' }}>
          {resource.mediaType[`${lowerCaseLocale}Translation`]}{' '}
          <>
            {resource.contentType && (
              <> / {resource.contentType[`${lowerCaseLocale}Translation`]}</>
            )}
          </>
        </div>
        <div className="MineItems__footer">
          <div style={{ display: 'flex' }}>
            <VisitLinkButton href={resource.url} />
            <SocialMediaShareButton resource={resource} />
          </div>
          {/* <NavigateSiteButton to={`/resource-details/${resource.id}`}>
            {t('Details')}
          </NavigateSiteButton> */}
          {/* <NavigateSiteButton to={`/add-edit-resource/${resource.id}`}>
            {t('Edit')}
          </NavigateSiteButton> */}
          <div></div>
        </div>
      </div>
    );
  });
};

export default UserResources;
