/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:fcfd20fd-dd6d-41bc-99d4-7519a908e0e7",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_TXjOxc0SF",
    "aws_user_pools_web_client_id": "7837v5ojc47vvntl34lqplsi5i",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://mrahu6dwargtxjn6mrcuryhtlq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://v9jm9fm92h.execute-api.eu-central-1.amazonaws.com/pro",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
