/* eslint-disable complexity */
import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';

import video from '../../assets/svgs/video.svg';
import music from '../../assets/svgs/music.svg';
import apps from '../../assets/svgs/apps.svg';
import sites from '../../assets/svgs/sites.svg';
import socialNets from '../../assets/svgs/social-nets.svg';
import books from '../../assets/svgs/books.svg';
import articles from '../../assets/svgs/articles.svg';

const MediaIconFilter = ({ items, refine, createURL }) => {
  const getIcon = item => {
    if (
      item.label === 'video' ||
      item.label === 'видео' ||
      item.label === 'бейне'
    )
      return <img src={video} alt="video" />;
    if (item.label === 'website' || item.label === 'веб-сайт')
      return <img src={sites} alt="sites" />;
    if (
      item.label === 'app' ||
      item.label === 'приложение' ||
      item.label === 'қолданба'
    )
      return <img src={apps} alt="apps" />;
    if (
      item.label === 'social media' ||
      item.label === 'Соц. сети' ||
      item.label === 'Әлеуметтік медиа'
    )
      return <img src={socialNets} alt="social-networks" />;
    if (
      item.label === 'audio' ||
      item.label === 'аудио' ||
      item.label === 'дыбыс'
    )
      return <img src={music} alt="audio" />;
    if (
      item.label === 'book / booklet / study guide' ||
      item.label === 'книга' ||
      item.label === 'кітап'
    )
      return <img src={books} alt="books" />;
    if (
      item.label === 'article' ||
      item.label === 'статья' ||
      item.label === 'мақала'
    )
      return <img src={articles} alt="articles" />;

    return <img src={video} alt="video" />;
  };

  return (
    <ul className="media-icon-filter-container">
      {items.map((item, idx) => (
        <li key={idx}>
          <a
            href={createURL(item.value)}
            style={{
              fontWeight: item.isRefined ? 'bold' : '',
            }}
            onClick={event => {
              event.preventDefault();
              refine(item.value);
            }}
          >
            {getIcon(item)}
            {item.label}
            {/* {`(${item.count})`} */}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default connectRefinementList(MediaIconFilter);
