import { Translations } from '@aws-amplify/ui-components';
import { Auth, I18n } from 'aws-amplify';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBox } from 'react-instantsearch-dom';
import { Link, useHistory } from 'react-router-dom';
import {
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { AppContext } from '../../App';
import logo from '../../assets/svgs/media-oasis-logo.svg';
import SwitchSiteLanguage from '../common/SwitchSiteLanguage';

const NavbarComponent = () => {
  const [{ currentCognitoUser, userGroups }] = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  I18n.setLanguage(i18n.language);
  I18n.putVocabulariesForLanguage(i18n.language, {
    [Translations.SIGN_OUT]: t('Sign Out'),
  });

  const toggle = () => setIsOpen(!isOpen);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      history.push('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <Container>
      <Row>
        <Col md="12">
          <Navbar color="light" light expand="md">
            <NavbarBrand
              className="media-oasis-brand"
              data-placement="bottom"
              to="/"
              title="Media Oasis"
              tag={Link}
            >
              <img src={logo} alt="media-oasis-logo" />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />

            <SearchBox
              className="searchbox"
              translations={{
                placeholder: t('Search'),
              }}
              autofocus={true}
              focusShortcuts={[]}
            />

            <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink to="/add-edit-resource/0" tag={Link}>
                    {t('Add')}
                  </NavLink>
                </NavItem>
                <span className="menu-item-divider" />
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    {t('About us')}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem to="/about-us" tag={Link}>
                      {t('About us')}
                    </DropdownItem>
                    <DropdownItem to="/terms-of-service" tag={Link}>
                      {t('Terms of services')}
                    </DropdownItem>
                    <DropdownItem to="/statement-of-faith" tag={Link}>
                      {t('Our believes')}
                    </DropdownItem>
                    <DropdownItem to="/contact-us" tag={Link}>
                      {t('Contact Us')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <span className="menu-item-divider" />

                {currentCognitoUser && (
                  <>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        <i className="fa fa-user-circle" />{' '}
                        {currentCognitoUser.attributes.email.substring(
                          0,
                          currentCognitoUser.attributes.email.indexOf('@')
                        )}
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem to="/my-items" tag={Link}>
                          {t('My items')}
                        </DropdownItem>
                        <DropdownItem to="/user/login-profile" tag={Link}>
                          {t('Profile')}
                        </DropdownItem>
                        {userGroups.includes('APPROVERS' || 'ADMINS') && (
                          <DropdownItem to="/dashboard" tag={Link}>
                            {t('Dashboard')}
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <span className="menu-item-divider" />
                  </>
                )}
                <SwitchSiteLanguage />
                {currentCognitoUser ? (
                  <NavItem>
                    <a
                      className="nav-link login-menu-item"
                      onClick={handleSignOut}
                    >
                      <i className="fa fa-sign-out" /> {t('Logout')}
                    </a>
                  </NavItem>
                ) : (
                  <NavItem>
                    <NavLink
                      to="/my-items"
                      tag={Link}
                      className="login-menu-item"
                    >
                      <i className="fa fa-sign-in" /> {t('Login')}
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </Collapse>
          </Navbar>
        </Col>
      </Row>
    </Container>
  );
};

export default NavbarComponent;
