import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import './navigateSiteButton.scss';

const NavigateSiteButton = ({ children, to }) => {
  return (
    <Button to={to} tag={Link} color="primary" className="edit-button">
      {children}
    </Button>
  );
};

export default NavigateSiteButton;
