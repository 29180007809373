import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { ReactComponent as Kazakh } from '../../assets/svgs/kazakhstan.svg';
import { ReactComponent as Russian } from '../../assets/svgs/russia.svg';
import { ReactComponent as English } from '../../assets/svgs/uk.svg';

const SwitchSiteLanguage = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const getFlag = locale => {
    if (locale === 'en' || locale === 'en-US') return <English />;
    if (locale === 'ru' || locale === 'ru-RU') return <Russian />;
    if (locale === 'kk' || locale === 'kk-KZ') return <Kazakh />;
  };

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        <span style={{ display: 'inline-block', width: '1.5rem' }}>
          {getFlag(i18n.language)}
        </span>
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={() => changeLanguage('en')}>
          <span style={{ display: 'inline-block', width: '1.5rem' }}>
            {getFlag('en')} {t('English')}
          </span>
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage('ru')}>
          <span style={{ display: 'inline-block', width: '1.5rem' }}>
            {getFlag('ru')} {t('Russian')}
          </span>
        </DropdownItem>
        <DropdownItem onClick={() => changeLanguage('kk')}>
          <span style={{ display: 'inline-block', width: '1.5rem' }}>
            {getFlag('kk')} {t('Kazakh')}
          </span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default SwitchSiteLanguage;
