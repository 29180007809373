import { API, graphqlOperation } from 'aws-amplify';

import * as myQueries from '../graphql/myQueries';

export default async function getLikedResources(_, params) {
  const response = await API.graphql(
    graphqlOperation(myQueries.listOfLikedResourcesByUserIdAndCreatedAt, {
      ...params,
    })
  );
  return response.data.listOfLikedResourcesByUserIdAndCreatedAt;
}
