import React from "react";

export default () => {
  return (
    <>
      <h1>What we are about / About Us</h1>
      <p>
        Media Oasis is a project aimed at gathering as many digital Christian
        resources as possible in one place so churches and believers can easily
        find something that will be useful for building up their faith.
      </p>
      <p>
        We gather resources from all over Central Asia and link them in a
        searchable database. These resources include videos, sermons, smartphone
        apps, books, articles, websites and much more.
      </p>
      <p>
        We are a non-profit group from multiple organizations and churches whose
        aim is to strengthen and equip the Central Asian church through
        providing and gathering digital resources in all Central Asian languages
        and making them easily accessible to everyone.
      </p>
    </>
  );
};
