import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'reactstrap';
import ResourceDetails from '../../resourceDetails/ResourceDetails';
import './viewDetailsButton.scss';

const ViewDetailsButton = ({ hit, children }) => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  return (
    <>
      {children ? (
        <Button
          type="button"
          color="link"
          onClick={toggle}
          className="hit-name-button"
        >
          <span className="hit-name">{children}</span>
        </Button>
      ) : (
        <Button
          className="view-resource-details-button"
          type="button"
          onClick={toggle}
          color="primary"
        >
          <i className="fa fa-info-circle" style={{ marginRight: '5px' }} />
          {t('Details')}
        </Button>
      )}
      <Modal isOpen={modal} toggle={toggle} className="resource-details-modal">
        <ResourceDetails
          resourceIdProps={hit.objectID}
          toggle={toggle}
          hit={hit}
        />
      </Modal>
    </>
  );
};

export default ViewDetailsButton;
