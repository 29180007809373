import React from 'react';

export default () => {
  return (
    <>
      <h1>Қызмет шарттары</h1>
      <p>15 сәуір, 2019ж. жаңартылған</p>
      <p>
        Бұл Қызмет (сервис) келісім-шарты сіздің Медия Оазис вебсайтымен (
        <a href="https://mediaoasis.org/">https://mediaoasis.org/</a>) қатынасыңызды бағыттайды, оның операторы Digital
        Nomads group.
      </p>
      <p>Осы сервисті қолданбастан бұрын Сервис келісім-шартын мұқият оқып алыңыз.</p>
      <p>
        Сервиске қосылу және оны қолдану осы шарттарды қабылдауыңызға және оларды сақтауыңызға байланысты болады. Осы
        шарттар сервиске кіретіндерге, пайдаланушыларға және сервиске кіре алушыларға, оны пайдаланушыларға қатысты.
      </p>
      <p>
        Сервиске кіру немесе пайдалану арқылы сіз өзіңізді осы шарттармен байлайсыз. Егер осы шарттардың кез келген
        бөлігімен келіспесеңіз онда сервиске кіре алмайсыз
      </p>
      <h2>Мазмұн (контент)</h2>
      <p>
        Біздің сервисіміз сізге белгілі бір мәліметті, мәтінді, графикаларды, видеоларды және басқа материалдарды
        (&laquo;Мазмұнды&raquo;) сайтқа қоюға, сілтемелер көрсетуге, бөлісуге, қол жетімді етуге мүмкіндік береді. Сіз
        сервиске орналастыратын Мазмұнның (Контенттің) заңдылығына, сенімділігіне және орындылығына өзіңіз жауаптысыз.
      </p>
      <p>
        Контентті сервиске орналастыру арқылы сіз бізге сол контентті сервисте және ол арқылы қолдану, өзгерту, халыққа
        жария ету, көбейту және тарату құқығын бересіз. Сіз сервиске ұсынған, орналастырған, көрсеткен қандай да болсын
        контентке құқығыңызды сақтап қаласыз және сол құқығыңызды қорғауға жауаптысыз. Осы лиценизия (рұқсат) бізге
        сіздің қойған контентіңізді сервистің басқа пайдаланушыларына қолжетімді ету құқығын беретінімен сіз келісесіз,
        басқа пайдаланушылар сіздің контентіңізді осы шарт ережелері бойынша қолдана алады.&nbsp;
      </p>
      <p>
        Сіз келесілерге өкілдік етіп, кепілдік бересіз: (i) контент сіздікі екендігіне (сіздің меншігіңіз) немесе сіз
        оны қолдануға құқығыңыз бар және сол құқықты және лицензияны осы шартта айтылғандай бізге бересіз, (ii) сервисте
        немесе сервис арқылы өз контентіңізді орналастыру жеке құпиялық құқықты, жариялық құқықты, авторлық құқықты,
        контракт құқығын немесе кез келген адамның қандай да болсын құқықтарын бұзбайды.
      </p>
      <h2>Аккаунттар (есептік жазба)</h2>
      <p>
        Сіз бізде аккаунт ашқанда, бізге нақты, толық, қазіргі уақытқа сай мәліметтерді беруіңіз керек. Мұны орындамау
        шарттың бұзылуын білдіреді, бұл біздің сервисіміздегі аккаунтыңыздың бірден жабылуына әкелуі мүмкін.
      </p>
      <p>
        Сіз сервиске кіретін құпия сөзіңіздің (пароліңіздің) қауіпсіздігін қорғауға және сіздің пароліңізбен жасалған
        кез келген іс-әрекет үшін жауаптысыз, тіпті сіздің пароліңіз үшінші тарап қызметпен (сервиспен) қолданылса
        да.&nbsp;
      </p>
      <p>
        Пароліңізді үшінші тарапқа жарияламауға келісесіз. Сіз өзіңіздің аккаунтыңыздың қауіпсіздігі бұзылғанын немесе
        рұқсатсыз пайдаланғанын білген сәтте бізге дереу хабарлауыңыз керек.
      </p>
      <p>
        Басқа адамның, заңды тұлғаның, пайдалануға рұқсат етілмеген атын пайдаланушы ат (имя пользователя) ретінде
        пайдалана алмайсыз, сонымен қатар басқа адамның немесе заңды тұлғаның құқығындағы сауда белгісін тиісті
        рұқсатсыз пайдалана алмайсыз, немесе қорлайтын, ұятсыз, әдепсіз атты да қолдануға болмайды.
      </p>
      <h2>Басқа веб-сайттарға сілтеме</h2>
      <p>
        Біздің сервисте Медия Оазис иелігінде немесе бақылауында емес үшінші тарап веб-сайттарына, сервистеріне
        сілтемелер болуы мүмкін.
      </p>
      <p>
        Медия Оазис үшінші тарап веб-сайттарының контентін, құпиялылық саясаттарын немесе әрекеттерін бақыламайды және
        оларға жауапты емес.
      </p>
      <p>
        Сондай-ақ, Медиа Оазистің сол веб-сайттарда немесе сервистерде болатын кез-келген контентке, тауарларға жауапты
        емес, және веб-сайттарға немесе сервистерге байланысты туындаған кез келген залал үшін тікелей немесе жанама
        түрде жауапты болмайтынын сіз кейінірек білесіз және онымен келісесіз;
      </p>
      <p>
        Сіз кірген кез келген үшінші тарап веб-сайттары мен сервистерінің шарттары мен құпиялылық саясатын оқып
        шығуыңызға кеңес береміз.
      </p>
      <h2>Қызметті тоқтату</h2>
      <p>
        Қандай да болсын себеппен сіз шарттарды бұзсаңыз, біз сіздің аккаунтыңызды алдын-ала ескертусіз немесе
        жауапкершіліктен бас тарта, шектеусіз тоқтата аламыз.
      </p>
      <p>
        Тоқтатылғаннан кейін сервисті пайдалану құқығы дереу аяқталады. Егер сіз өзіңіздің аккаунтыңызды тоқтатқыңыз
        келсе, сіз сервисті пайдалануды тоқтата саласыз.
      </p>
      <h2>Жаупкершілікті шектеу</h2>
      <p>
        &nbsp;&laquo;Медия Оазис&raquo; компаниясы, оның директорлары, қызметкерлері, серіктестері, агенттері,
        қамтамасыз етушілері, филиал өкілдері ешқандай жағдайда кез-келген жанама, кездейсоқ, әдейі, салдарлық, немесе
        айыптық шығындар үшін жауапкершілік көтермейді, оған шектеусіз, пайданы жоғалту, деректерді, гудвилл немесе
        басқа да материалдық емес жоғалтулар да кіреді, бұлар келесілердің салдарынан болады (i) сіздің сервиске
        кіруіңіз, қолдануыңыз немесе кіре алмауыңыз, қолдана алмауыңыз ; (ii) Сервистегі үшінші тараптың әрекеттері
        немесе контенті; (iii) сервистен алынған кез келген контент; (iv) сіздің мәліметтеріңізді немесе контентіңізді
        рұқсатсыз ашып қолдану немесе өзгерту, кепілдікке, контрактқа, азаматтық құқықты бұзуға (соның ішінде
        немқұрайлық) немесе басқа да заңды теорияға негізделген болса да, біз осындай зиянды ықтималдығы туралы хабардар
        болсақ та, болмасақ та, және тіпті осы құжатта айтылған құқықты қорғау шарты оның маңызды мақсатын орындамаған
        болса да.&nbsp;
      </p>
      <h2>Жаупкершіліктен бас тарту</h2>
      <p>
        Сіз сервисті қолданғанда тәуекелге барасыз. Сервис (қызмет) &laquo;сол күйінде&raquo; және &laquo;қол
        жетерлік&raquo; негізде беріледі. Сервис ашық немесе астыртын айтылған ешқандай кепілдіксіз беріледі, ол
        қамтылған, бірақ шектелмеген, нарықтықты білдіретін кепілдіктер, белгілі бір мақсатқа сәйкес келетін, жұмыс
        барысын бұзбайтын.
      </p>
      <p>
        Медия Оазистің еншілес компаниялары, филиалдары және оның лицензиарлары келесілерге кепілдік бермейді: a{')'}{' '}
        Сервис
        {'('}қызмет{')'} үзілмей жұмыс істеуіне, қауіпсіздігіне және кез келген уақытта немесе кез келген жерде қол
        жетімді болуына; b{')'} әрбір қате немесе ақаулар түзетіледі; c{')'} Сервис вируссыз немесе басқа да зиянды
        құрамсыз; немесе d{')'}
        Сервисті қолдану нәтижесі сіздің талаптарыңызға сай болады деген кепілдік бермейді .
      </p>
      <h2>Басқарушы заң</h2>
      <p>
        Осы Шарттар Қазақстан Республикасының заңдарына сәйкес реттеледі және түсіндіріледі, оның ережелерінің
        қайшылықтарына қарамастан.
      </p>
      <p>
        Осы Шарттардың кез-келген құқығын немесе ережелерін орындай алмай қалғанымыз, біздің сол құқықтардан бас
        тартқанымыз деп саналмайды
      </p>
      <p>
        Егер осы Шарттардың қандай да бір ережесін сот жарамсыз немесе орындалуы мүмкін емес деп тапса, Шарттардың
        қалған ережелері күшінде қалады.
      </p>
      <p>
        Бұл Шарттар біздің сервисімізге қатысты бүкіл келісімшартты құрайды және сервиске қатысты кез-келген арамыздағы
        бұрынғы келісімдерді ығыстырады және алмастырады.
      </p>
      <h2>Өзгерістер</h2>
      <p>
        Біз осы шарт ережелерін кез-келген уақытта өз қалауымыз бойынша өзгертуге немесе ауыстыруға құқығымызды
        сақтаймыз.Егер қайта қарау маңызды болса, онда жаңа шарт күшіне енгенге дейін кемінде 30 күн бұрын хабарлауға
        тырысамыз. Маңызды өзгеріс не болатынын біз өз бетімізше анықтаймыз.&nbsp;Осы қайта қаралған түзетулер күшіне
        енгеннен кейін сервиске кіруді немесе оны пайдалануды жалғастыру арқылы, сіз өзгертілген шарттармен байлануға
        келісесіз.
      </p>
      <p>Егер сіз жаңа шарттарммен келіспесеңіз, онда сервисті қолдануды тоқтатуыңызды сұраймыз.</p>
      <h2>Бізбен хабарласыңыз</h2>
      <p>Осы шарттарға қатысты сұрақтарыңыз болса, бізбен хабарласуыңызды сұраймыз.</p>
    </>
  );
};
