import React, { useContext } from 'react';
import Helmet from 'react-helmet';

import BeliefsEn from './BeliefsEn';
import AboutUsEn from './AboutUsEn';
import TermsEn from './TermsEn';
import AboutUsRu from './AboutUsRu';
import BeliefsRu from './BeliefsRu';
import TermsRu from './TermsRu';
import AboutUsKk from './AboutUsKk';
import BeliefsKk from './BeliefsKk';
import TermsKk from './TermsKk';
import Layout from '../layout/Layout';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../App';

const Page = ({ pageType }) => {
  const [
    {
      localeState: { lowerCaseLocale },
    },
  ] = useContext(AppContext);

  const { t } = useTranslation();

  const renderMetaData = () => (
    <Helmet
      title={`Media Oasis | ${t(pageType)}`}
      meta={[
        {
          name: 'description',
          content: `Media Oasis - ${t(pageType)}`,
        },
      ]}
    />
  );

  const getPage = () => {
    if (pageType === 'About us' && lowerCaseLocale === 'en') {
      return <AboutUsEn />;
    }
    if (pageType === 'Our Beliefs' && lowerCaseLocale === 'en') {
      return <BeliefsEn />;
    }
    if (pageType === 'Terms of Service' && lowerCaseLocale === 'en') {
      return <TermsEn />;
    }
    if (pageType === 'About us' && lowerCaseLocale === 'ru') {
      return <AboutUsRu />;
    }
    if (pageType === 'Our Beliefs' && lowerCaseLocale === 'ru') {
      return <BeliefsRu />;
    }
    if (pageType === 'Terms of Service' && lowerCaseLocale === 'ru') {
      return <TermsRu />;
    }
    if (pageType === 'About us' && lowerCaseLocale === 'kk') {
      return <AboutUsKk />;
    }
    if (pageType === 'Our Beliefs' && lowerCaseLocale === 'kk') {
      return <BeliefsKk />;
    }
    if (pageType === 'Terms of Service' && lowerCaseLocale === 'kk') {
      return <TermsKk />;
    }
  };

  return (
    <Layout>
      {renderMetaData()}
      {getPage()}
    </Layout>
  );
};

export default Page;
