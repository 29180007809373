import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import './visitLinkButton.scss';

const VisitLinkButton = ({ href }) => {
  const { t } = useTranslation();
  return (
    <Button
      type="button"
      onClick={() => window.open(href)}
      color="primary"
      className="visit-link-button"
    >
      <i className="fa fa-external-link" style={{ marginRight: '5px' }} />
      {t('Visit link')}
    </Button>
  );
};

export default VisitLinkButton;
