import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FacebookIcon,
  EmailIcon,
  TelegramIcon,
  TwitterIcon,
  VKIcon,
  WhatsappIcon,
  FacebookShareButton,
  WhatsappShareButton,
  VKShareButton,
  TelegramShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import { Modal, ModalBody, ModalHeader, Alert, Button } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { AppContext } from '../../../App';
import { getTranslatedAttrib } from '../../resourceDetails/ResourceDetailsToShare';
import {
  getTranslatedDescription,
  getTranslatedTitle,
} from '../../search/helpers';
import './socialMediaShareButton.scss';

export function GetShareButton({ resource, upperCaseLocale, socialMediaName }) {
  const getUrl = () => {
    return `${origin}/resource-details/${
      resource.objectID ? resource.objectID : resource.id
    }?redir`;
  };

  const getTitle = () => {
    // eslint-disable-next-line no-nested-ternary
    return resource.objectID
      ? getTranslatedTitle(upperCaseLocale, resource) === 'titleOthers[0]'
        ? resource.titleOthers[0]
        : resource[getTranslatedTitle(upperCaseLocale, resource)]
      : getTranslatedAttrib(resource.titles, 'title', upperCaseLocale);
  };

  const getDescription = () => {
    // eslint-disable-next-line no-nested-ternary
    return resource.objectID
      ? getTranslatedDescription(upperCaseLocale, resource) ===
        'descriptionOthers[0]'
        ? resource.descriptionOthers[0]
        : resource[getTranslatedDescription(upperCaseLocale, resource)]
      : getTranslatedAttrib(
          resource.descriptions,
          'description',
          upperCaseLocale
        );
  };

  switch (socialMediaName) {
    case 'facebook':
      return (
        <FacebookShareButton
          url={getUrl()}
          quote={`${getTitle()}: ${getDescription()}`}
          hashtag="mediaoasis"
          className="share-button-icon"
        >
          <FacebookIcon size={36} round />
        </FacebookShareButton>
      );
    case 'whatsapp':
      return (
        <WhatsappShareButton
          url={getUrl()}
          title={getTitle()}
          className="share-button-icon"
          separator=": "
        >
          <WhatsappIcon size={36} round />
        </WhatsappShareButton>
      );
    case 'telegram':
      return (
        <TelegramShareButton
          url={getUrl()}
          title={getTitle()}
          className="share-button-icon"
        >
          <TelegramIcon size={36} round />
        </TelegramShareButton>
      );
    case 'vk':
      return (
        <VKShareButton
          url={getUrl()}
          title={getTitle()}
          className="share-button-icon"
        >
          <VKIcon size={36} round />
        </VKShareButton>
      );
    case 'twitter':
      return (
        <TwitterShareButton
          url={getUrl()}
          title={getTitle()}
          hashtags={['mediaoasis']}
          className="share-button-icon"
        >
          <TwitterIcon size={36} round />
        </TwitterShareButton>
      );
    case 'email':
      return (
        <EmailShareButton
          url={getUrl()}
          subject={getTitle()}
          body={`${getTitle()}: ${getDescription()}`}
          className="share-button-icon"
        >
          <EmailIcon size={36} round />
        </EmailShareButton>
      );
    default:
      return null;
  }
}

const SocialMediaShareButton = ({ resource }) => {
  const [
    {
      localeState: { upperCaseLocale },
    },
  ] = useContext(AppContext);

  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);

  return (
    <div>
      <Button
        className="share-button"
        onClick={toggle}
        color="info"
        type="button"
      >
        <i className="fa fa-share-alt" style={{ marginRight: '5px' }} />
        {t('Share')}
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('Share')}</ModalHeader>
        <ModalBody>
          <GetShareButton
            resource={resource}
            upperCaseLocale={upperCaseLocale}
            socialMediaName="facebook"
          />
          <GetShareButton
            resource={resource}
            upperCaseLocale={upperCaseLocale}
            socialMediaName="whatsapp"
          />
          <GetShareButton
            resource={resource}
            upperCaseLocale={upperCaseLocale}
            socialMediaName="telegram"
          />
          <GetShareButton
            resource={resource}
            upperCaseLocale={upperCaseLocale}
            socialMediaName="vk"
          />
          <GetShareButton
            resource={resource}
            upperCaseLocale={upperCaseLocale}
            socialMediaName="twitter"
          />
          <GetShareButton
            resource={resource}
            upperCaseLocale={upperCaseLocale}
            socialMediaName="email"
          />
          <div className="share-copy-container">
            <div className="share-copy-url">{resource.url}</div>
            <CopyToClipboard
              text={resource.url}
              onCopy={() => setVisible(true)}
            >
              <Button type="button" color="link">
                {t('Copy')}
              </Button>
            </CopyToClipboard>
          </div>
          <Alert color="success" isOpen={visible} toggle={onDismiss}>
            {t('Url has been copied')}
          </Alert>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SocialMediaShareButton;
