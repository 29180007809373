import React, { useState, useEffect, useContext } from 'react';
import {
  ClearRefinements,
  HierarchicalMenu,
  Menu,
  RefinementList,
} from 'react-instantsearch-dom';
import { Collapse, Button } from 'reactstrap';
import { AppContext } from '../../App';

const getBibleBookRefsForLocale = (upperCaseLocale, t) => {
  if (upperCaseLocale === 'Ru') {
    return (
      <HierarchicalMenu
        showMore={true}
        key="bibleBookRefs"
        attributes={['bibleBookRefsRu.lvl0', 'bibleBookRefsRu.lvl1']}
        translations={{
          showMore(expanded) {
            return expanded ? `${t('Show less')}...` : `${t('Show more')}...`;
          },
        }}
      />
    );
  }
  if (upperCaseLocale === 'Kk') {
    return (
      <HierarchicalMenu
        showMore={true}
        key="bibleBookRefs"
        attributes={['bibleBookRefsKk.lvl0', 'bibleBookRefsKk.lvl1']}
        translations={{
          showMore(expanded) {
            return expanded ? `${t('Show less')}...` : `${t('Show more')}...`;
          },
        }}
      />
    );
  }
  return (
    <HierarchicalMenu
      showMore={true}
      key="bibleBookRefs"
      attributes={['bibleBookRefsEn.lvl0', 'bibleBookRefsEn.lvl1']}
      translations={{
        showMore(expanded) {
          return expanded ? `${t('Show less')}...` : `${t('Show more')}...`;
        },
      }}
    />
  );
};

const Panel = ({ title, children, id }) => (
  <div id={id}>
    <h5>{title}</h5>
    {children}
  </div>
);

const RenderInnerFacets = () => {
  const [
    {
      localeState: { upperCaseLocale, t },
    },
  ] = useContext(AppContext);

  return (
    <div className="search-facets">
      <div className="header-on-prime-bkgrnd">{t('Search options')}</div>
      <Panel title={t('Language')} id="language">
        <Menu
          searchable={true}
          attribute={`language${upperCaseLocale}`}
          showMore={true}
          translations={{
            showMore(expanded) {
              return expanded ? `${t('Show less')}...` : `${t('Show more')}...`;
            },
          }}
          showMoreLimit={100}
        />
      </Panel>
      <Panel title={t('Media')} id="mediaType">
        <RefinementList attribute={`mediaType${upperCaseLocale}`} />
      </Panel>
      <Panel title={t('Category')} id="category">
        <RefinementList
          searchable={true}
          showMore={true}
          attribute={`categories${upperCaseLocale}`}
          translations={{
            showMore(expanded) {
              return expanded ? `${t('Show less')}...` : `${t('Show more')}...`;
            },
          }}
        />
      </Panel>
      <Panel title={t('Content Type')} id="contentType">
        <RefinementList
          searchable={true}
          showMore={true}
          attribute={`contentType${upperCaseLocale}`}
          translations={{
            showMore(expanded) {
              return expanded ? `${t('Show less')}...` : `${t('Show more')}...`;
            },
          }}
        />
      </Panel>
      <Panel title={t('Bible references')} id="bibleRefs">
        {getBibleBookRefsForLocale(upperCaseLocale, t)}
      </Panel>
      <ClearRefinements translations={{ reset: t('Clear filters') }} />
    </div>
  );
};

const Facets = () => {
  const [
    {
      localeState: { t },
    },
  ] = useContext(AppContext);

  const [collapseState, setCollapseState] = useState(false);
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    if (window) {
      let media = window.matchMedia('(max-width: 765px)');
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      let listener = () => setMatches(media.matches);
      media.addListener(listener);
      return () => media.removeListener(listener);
    }
  }, []);

  return (
    <>
      {matches && (
        <div>
          <Button
            color="primary"
            onClick={() => setCollapseState(!collapseState)}
            style={{ marginBottom: '1rem' }}
          >
            {t('Search options')}
          </Button>
          <Collapse isOpen={collapseState}>
            <RenderInnerFacets />
          </Collapse>
        </div>
      )}
      {!matches && <RenderInnerFacets />}
    </>
  );
};

export default Facets;
