import React from "react";

export default () => {
  return (
    <>
      <h2>О Нас</h2>
      <p>
        Media Oasis - это проект, направленный на сбор как можно большего
        количества цифровых христианских ресурсов в одном месте, для чтобы
        церкви и верующие могли легко найти то, что будет полезно для укрепления
        их веры.
      </p>
      <p>
        Мы собираем ресурсы со всей Центральной Азии и вносим их в поисковую
        базу данных. Эти ресурсы включают в себя: видео, проповеди, приложения
        для смартфонов, книги, статьи, веб-сайты и многое другое.
      </p>
      <p>
        Мы являемся некоммерческой группой, состоящей из нескольких организаций
        и церквей, нашей целью является укрепление и оснащение
        центральноазиатской Церкви путем сбора и предоставления цифровых
        ресурсов на всех языках Центральной Азии и предоставления легкого
        доступа к ним.
      </p>
    </>
  );
};
