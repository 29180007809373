import { API, graphqlOperation } from 'aws-amplify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as mutations from '../../graphql/mutations';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { AppContext } from '../../App';
import TextInput from '../common/TextInput';
import Layout from '../layout/Layout';
import Alert from '@material-ui/lab/Alert';
import { queryCache, useQuery } from 'react-query';
import getCurrentUser from '../../api/getCurrentUser';
import LoadingComp from '../common/LoadingComp';
import { useHistory } from 'react-router-dom';

const EditProfile = () => {
  const [{ currentCognitoUser }] = useContext(AppContext);
  const { t } = useTranslation();

  const history = useHistory();

  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [alertObj, setAlertObj] = useState(null);

  const { isLoading, data: currentUser } = useQuery(
    ['getCurrentUser', currentCognitoUser && currentCognitoUser.username],
    getCurrentUser,
    { enabled: currentCognitoUser }
  );

  if (isLoading) {
    return <LoadingComp style={{ marginTop: '-2rem' }} />;
  }

  return (
    <Layout subTitle={t('Edit profile')}>
      <Row>
        <Col sm={12} md={{ size: 6, offset: 3 }}>
          <h2>{t('Edit profile')}</h2>

          {alertObj && (
            <Alert color={alertObj.type} isOpen={visible} toggle={onDismiss}>
              {alertObj.message}
            </Alert>
          )}
          <Formik
            initialValues={{
              id: currentUser.id,
              firstName: currentUser.firstName,
              lastName: currentUser.lastName,
            }}
            onSubmit={async values => {
              try {
                await API.graphql(
                  graphqlOperation(mutations.updateUser, {
                    input: values,
                  })
                );
                queryCache.invalidateQueries('getCurrentUser');
                setAlertObj({
                  type: 'success',
                  message: `${t('Profile has been updated')}`,
                });
              } catch (e) {
                setAlertObj({
                  type: 'danger',
                  message: `${t(
                    'There was problem to update your profile. Please try later'
                  )}`,
                });
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormGroup>
                  <Label htmlFor="firstName">{t('First Name')}</Label>
                  <Field name="firstName" component={TextInput} />
                  <ErrorMessage
                    className="form-error"
                    component="div"
                    name="firstName"
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="lastName">{t('Last Name')}</Label>
                  <Field name="lastName" component={TextInput} />
                  <ErrorMessage
                    className="form-error"
                    component="div"
                    name="lastName"
                  />
                </FormGroup>
                <Row style={{ marginBottom: '1rem' }}>
                  <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      color="secondary"
                      onClick={() => history.goBack()}
                      style={{ marginRight: '1rem' }}
                    >
                      {t('Cancel')}
                    </Button>

                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && <i className="fa fa-spinner fa-pulse" />}{' '}
                      {t('Submit')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Layout>
  );
};

export default EditProfile;
