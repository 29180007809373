import { API, graphqlOperation } from 'aws-amplify';
import React, { useState } from 'react';
import { queryCache } from 'react-query';
import * as myMutations from '../../graphql/myMutations';
import * as myQueries from '../../graphql/myQueries';
import './likeButton.scss';
import LikeIcon from './LikeIcon';

const UnlikeButton = ({ hit, currentUser }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnClick = async () => {
    setIsSubmitting(true);

    try {
      const promRes = await Promise.all([
        await API.graphql(
          graphqlOperation(myMutations.myDeleteUserResource, {
            input: {
              id: currentUser.favoriteResources.items.find(
                item => item.resourceID === hit.id
              ).id,
            },
          })
        ),
      ]);
      if (promRes) {
        queryCache.invalidateQueries('getLikedResources');
        queryCache.invalidateQueries('getCurrentUser');
        setIsSubmitting(false);
      }
    } catch (e) {
      setIsSubmitting(false);
      console.log(e);
    }
  };

  return (
    <button type="button" className="like-button" onClick={handleOnClick}>
      <div className="like-button-inner-container">
        {isSubmitting && <i className="fa fa-spinner fa-pulse" />}
        <LikeIcon title="like" isLiked={true} />{' '}
      </div>
    </button>
  );
};

export default UnlikeButton;
