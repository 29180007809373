import { API, graphqlOperation } from 'aws-amplify';

import * as myQueries from '../graphql/myQueries';

export default async function getUserResources(_, params) {
  const response = await API.graphql(
    graphqlOperation(myQueries.myResourcesByContributorIdAndCreatedDate, {
      ...params,
    })
  );
  return response.data.resourcesByContributorIdAndCreatedDate;
}
