import React from "react";

export default () => {
  return (
    <>
      <h1>Сенім пайымдауы</h1>
      <p>
        Медия Оазис Інжілдік мәсіхшілік сенімге бағышталған, Бүкіләлемдік
        Інжілдік Альянстің Сенім пайымдауында айтылғандай:{" "}
        <a href="http://www.worldea.org/whoweare/statementoffaith">
          Statement of Faith
        </a>
        :
      </p>
      <p>Біздің сенетініміз:</p>
      <p>
        ...Киелі жазбаны Құдай берген, Құдайдың рухының жетелеуімен жазылған,
        мінсіз, толық сенімді; ... сенім мен мінез-құлық мәселелеріне қатысты
        жоғарғы билік ...деп сенеміз.
      </p>
      <p>
        Құдай біреу , мәңгіліктен үш тұлғада бар боп келеді &ndash; Әке, Ұл,
        Киелі Рух...
      </p>
      <p>
        Иеміз Иса Мәсіх, адам тәнінде көрінген Құдай, Оның кінәратсыз
        туылғанына, Оның күнәсіз адам тәніндегі өмір сүргеніне, Оның құдайлық
        кереметтеріне, Оның орынбасарлық құнөтеушілік өліміне, Оның тәндік қайта
        тірілгеніне, Оның көкке көтерілгеніне, Оның келістірушілік ісіне, және
        Оның билікпен салтанатпен қайта келуіне сенеміз...
      </p>
      <p>
        Адасқан және күнәһар адамдар істеріне қарамастан Иеміз Иса Мәсіхтің
        төгілген қанына сенімі арқылы құтқарылады, Киелі Рух арқылы қайта
        туылады...
      </p>
      <p>
        Киелі Рух бойында болғандықтан сенушілер киелі өмір сүруге, Иеміз Иса
        Мәсіх туралы куәлік етуге және Оған қызмет етуге қабілетті.
      </p>
      <p>
        Барлық сенушілердін рухының, қауымның, Мәсіхтің денесінің бірлігіне
        сенеміз...
      </p>
      <p>
        Құтқарылғандардың да, адасқандардың да қайта тірілуі, құтқарылғандар
        мәңгілік өмірге қайта тіріледі, ал адасқандар қарғысқа ұшырау үшін қайта
        тіріледі.
      </p>
      <p>
        Медия Оазистің сенімділік ұстанымын бейнелейтін неғұрлым толық
        пайымдауын 2010 жылғы Кейптаун міндеттемесінде табуға болады:
      </p>
      <p>
        <a href="https://www.lausanne.org/content/covenant/lausanne-covenant">
          https://www.lausanne.org/content/ctc/ctcommitment
        </a>
      </p>
      <p>
        {"("}
        орысшасы мына жерде:
        <a href="https://www.lausanne.org/ru/content/ctc/posvjasenie-iz-kejptauna">
          {" "}
          https://www.lausanne.org/ru/content/ctc/posvjasenie-iz-kejptauna
        </a>
        {")"}
      </p>
      <p>
        Медиа Оазис веб-сайтқа тіркеген материал осы Сенім пайымдауындағы ілімге
        сәйкес келу керек. Медиа Оазис редакциясы Киелі кітаптық, Інжілдік
        ілімнен ауытқулары бар материалдарды алып тастауға құқылы.
      </p>
      <p>
        Оған қоса, Медия Оазис редакция тобы төмендегідей материалдарға сілтеме
        жасамайды:
      </p>
      <p>1{")"} Жергілікті үкіметке қарсы шығатын ашық саяси материалдарға</p>
      <p>
        2{")"} Басқа сенім конфессияларын жамандайтын кез келген материалдарға
      </p>
      <p>
        3{")"} &laquo;Өркендеу інжілі&raquo; деген атпен танымал болған ілімді
        уағыздайтын кез келген материалға. (толығырақ мәліметті, Кейптаун
        міндеттемесінде IIE.5 қараңыз).
      </p>
    </>
  );
};
