import React from "react";

export default () => {
  return (
    <>
      <h1>Біз кімбіз / біз туралы</h1>
      <p>
        Медия Оазис жобасының мақсаты - санды мәсіхшілік ресурстарды бір жерге
        мүмкіндігі бойынша көбірек жинау, қауымдар мен жеке сенушілер өз
        сенімдерін нығайтуға пайдалы мәліметтерді оңай таба алулары үшін.
      </p>
      <p>
        Біз ресурстарды бүкіл Орталық Азиядан жинап, іздеп табуға болатын
        мәлімет базасына біріктіреміз. Ол ресурстарға видео, уағыздар, смартфон
        қосымшалары, кітаптар, мақалалар, вебсайттар және т.б. кіреді.
      </p>
      <p>
        Біз көптеген ұйымдардан және қауымдардан шыққан &hellip; топпыз,
        мақсатымыз Орталық Азиядағы қауымдарды нығайту және осы аймақтағы барлық
        тілдерде санды ресурстармен қауымдарды қамтамасыз ету және әркімге
        оларды оңай қолжетімді ету.
      </p>
    </>
  );
};
