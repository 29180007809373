import { API, graphqlOperation } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { AppContext } from '../../App';
import * as myQueries from '../../graphql/myQueries';
import LoadingComp from '../common/LoadingComp';
import NavigateSiteButton from '../common/navigateSiteButton/NavigateSiteButton';
import SocialMediaShareButton from '../common/shareButtons/SocialMediaShareButtons';
import VisitLinkButton from '../common/visitLinkButton/VisitLinkButton';
import Layout from '../layout/Layout';
import './resourceDetails.scss';

const locales = ['', 'En', 'Ru', 'Kk'];

export const getTranslatedAttrib = (attrArray, key, upperCaseLocale) => {
  const localeId = locales.findIndex(loc => loc === upperCaseLocale).toString();

  const attrInCurrentLocale = attrArray.find(
    attrObj => attrObj.langId === localeId
  );

  if (attrInCurrentLocale) {
    return attrInCurrentLocale[key];
  }

  const attrInOtherLocale = attrArray.find(
    // eslint-disable-next-line radix
    attrObj => parseInt(attrObj.langId) > 3
  );

  if (attrInOtherLocale) {
    return attrInOtherLocale[key];
  }

  return attrArray[0][key];
};

const ResourceDetailsToShare = () => {
  const [
    {
      currentCognitoUser,
      localeState: { upperCaseLocale, lowerCaseLocale },
    },
  ] = useContext(AppContext);

  const { t } = useTranslation();

  const { resourceId } = useParams();

  const [loading, setLoading] = useState(true);
  const [resource, setResource] = useState(null);

  useEffect(() => {
    const getResource = async () => {
      let receivedResource;
      if (currentCognitoUser) {
        receivedResource = await API.graphql(
          graphqlOperation(myQueries.myGetResource, {
            id: resourceId,
          })
        );
      } else {
        receivedResource = await API.graphql({
          query: myQueries.myGetResource,
          variables: { id: resourceId },
          authMode: 'AWS_IAM',
        });
      }
      setResource(receivedResource.data.getResource);
    };

    if (resourceId) {
      getResource();
    }
  }, [resourceId, currentCognitoUser]);

  useEffect(() => {
    if (resource) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [resource]);

  useEffect(() => {
    if (window.location.search === '?redir' && !loading) {
      window.location.href = resource.url;
    }
  }, [window.location.search, loading]);

  if (loading) {
    return (
      <LoadingComp
        style={{ marginTop: '-2rem', maxHeight: '200px', minHeight: '200px' }}
      />
    );
  }

  return (
    <Layout
      subTitle={getTranslatedAttrib(resource.titles, 'title', upperCaseLocale)}
    >
      <Row>
        <Col sm={12} md={{ size: 6, offset: 3 }}>
          <h1>
            {' '}
            {getTranslatedAttrib(resource.titles, 'title', upperCaseLocale)}
          </h1>
          <div className="icon-desc-container">
            <div>
              <i className="fa fa-globe" />
            </div>
            <div>{resource.language[`${lowerCaseLocale}Translation`]}</div>
          </div>
          {resource.descriptions.length > 0 && (
            <div className="icon-desc-container">
              <div>
                <i className="fa fa-info-circle" />
              </div>
              <div>
                {getTranslatedAttrib(
                  resource.descriptions,
                  'description',
                  upperCaseLocale
                )}
              </div>
            </div>
          )}
          <div className="icon-desc-container">
            <div>
              <i className="fa fa-link" />
            </div>
            <a href={resource.url} target="_blank" rel="noopener noreferrer">
              {resource.url}
            </a>
          </div>
          <div className="pre-footer" style={{ fontWeight: '600' }}>
            {resource.mediaType[`${lowerCaseLocale}Translation`]}{' '}
            <>
              {resource.contentType && (
                <> / {resource.contentType[`${lowerCaseLocale}Translation`]}</>
              )}
            </>
          </div>
          <div className="MineItems__footer">
            <VisitLinkButton href={resource.url} />
            <SocialMediaShareButton resource={resource} />
            <NavigateSiteButton to={`/add-edit-resource/${resource.id}`}>
              {t('Edit')}
            </NavigateSiteButton>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default ResourceDetailsToShare;
