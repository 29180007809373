import React from 'react';
import { Spinner, Container } from 'reactstrap';

const LoadingComp = ({ style }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // position: 'absolute',
        minHeight: '100vh',
        width: '100%',
        ...style,
      }}
    >
      <Spinner color="primary" />
    </div>
  );
};

export default LoadingComp;
