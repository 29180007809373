export const myCreateResourceCategory = /* GraphQL */ `
  mutation MyCreateResourceCategory(
    $input: CreateResourceCategoryInput!
    $condition: ModelResourceCategoryConditionInput
  ) {
    createResourceCategory(input: $input, condition: $condition) {
      id
    }
  }
`;

export const myCreateResourceBibleBook = /* GraphQL */ `
  mutation MyCreateResourceBibleBook(
    $input: CreateResourceBibleBookInput!
    $condition: ModelResourceBibleBookConditionInput
  ) {
    createResourceBibleBook(input: $input, condition: $condition) {
      id
    }
  }
`;

export const myCreateResource = /* GraphQL */ `
  mutation MyCreateResource(
    $input: CreateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    createResource(input: $input, condition: $condition) {
      id
      url
      titles {
        langId
        title
      }
      descriptions {
        langId
        description
      }
      language {
        id
        enTranslation
        ruTranslation
        kkTranslation
        createdAt
        updatedAt
      }
      mediaType {
        id
        enTranslation
        ruTranslation
        kkTranslation
        createdAt
        updatedAt
      }
      contentType {
        id
        enTranslation
        ruTranslation
        kkTranslation
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          resourceID
          categoryID
          createdAt
          updatedAt
          category {
            id
            enTranslation
            ruTranslation
            kkTranslation
          }
        }
        nextToken
      }
      bibleBookRefs {
        items {
          id
          resourceID
          bibleBookID
          chapterNumberRange
          createdAt
          updatedAt
          bibleBook {
            id
            enTranslation
            ruTranslation
            kkTranslation
          }
        }
        nextToken
      }
      owner
      contributor {
        id
      }
      missingLink
      inappropriate
      noteForApprover
      dateApproved
      createdAt
      updatedAt
      sensitive
      expirationDate
      parentSite
      upView
      upVote
    }
  }
`;

export const myUpdateResource = /* GraphQL */ `
  mutation MyUpdateResource(
    $input: UpdateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    updateResource(input: $input, condition: $condition) {
      id
      url
      titles {
        langId
        title
      }
      descriptions {
        langId
        description
      }
      language {
        id
        enTranslation
        ruTranslation
        kkTranslation
        createdAt
        updatedAt
      }
      mediaType {
        id
        enTranslation
        ruTranslation
        kkTranslation
        createdAt
        updatedAt
      }
      contentType {
        id
        enTranslation
        ruTranslation
        kkTranslation
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          resourceID
          categoryID
          category {
            id
            enTranslation
            ruTranslation
            kkTranslation
          }
        }
        nextToken
      }
      bibleBookRefs {
        items {
          id
          resourceID
          bibleBookID
          chapterNumberRange
          bibleBook {
            id
            enTranslation
            ruTranslation
            kkTranslation
          }
        }
        nextToken
      }
      contributor {
        id
      }
      # approver {
      #   id
      # }
      # approved
      missingLink
      inappropriate
      noteForApprover
      dateApproved
      createdAt
      updatedAt
      sensitive
      expirationDate
      parentSite
      upView
      upVote
    }
  }
`;

export const myCreateUserResource = /* GraphQL */ `
  mutation MyCreateUserResource(
    $input: CreateUserResourceInput!
    $condition: ModelUserResourceConditionInput
  ) {
    createUserResource(input: $input, condition: $condition) {
      id
      userID
      resourceID
      owner
      createdAt
      updatedAt
    }
  }
`;

export const myDeleteUserResource = /* GraphQL */ `
  mutation MyDeleteUserResource(
    $input: DeleteUserResourceInput!
    $condition: ModelUserResourceConditionInput
  ) {
    deleteUserResource(input: $input, condition: $condition) {
      id
      userID
      resourceID
      owner
      createdAt
      updatedAt
    }
  }
`;
