import React from 'react';
import LikeIcon from './LikeIcon';

const LikeButton = ({ hit, handleOnClick }) => {
  return (
    <button type="button" className="like-button" onClick={handleOnClick}>
      <LikeIcon title="like" isLiked={false} /> {hit.upVote}
    </button>
  );
};

export default LikeButton;
