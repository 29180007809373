import React from 'react';

function LikeIcon({ title, isLiked }) {
  // e40a0a
  return (
    <svg
      height="24"
      width="24"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <g fill={isLiked ? '#e40a0a' : '#bfbfbf'}>
        <path
          d="M33.551,3h-.114A12.161,12.161,0,0,0,24,7.449,12.156,12.156,0,0,0,14.57,3h-.117A12.506,12.506,0,0,0,2,15.43C2,27.2,22.5,44.062,23.367,44.774a1,1,0,0,0,1.266,0C25.505,44.062,46,27.2,46,15.426A12.5,12.5,0,0,0,33.551,3Z"
          fill={isLiked ? '#e40a0a' : '#bfbfbf'}
        />
      </g>
    </svg>
  );
}

export default LikeIcon;
