import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { AppContext } from '../../App';
import AuthenticatorComp from '../auth/Authenticator';
import Layout from '../layout/Layout';

const AuthRoute = ({ component: Component, ...rest }) => {
  const [{ currentCognitoUser }] = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Route
      {...rest}
      render={props =>
        currentCognitoUser ? (
          <Component {...props} />
        ) : (
          <Layout subTitle={t('Login')}>
            <AuthenticatorComp />
          </Layout>
        )
      }
    />
  );
};

export default AuthRoute;
