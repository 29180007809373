import { format } from 'date-fns';
import { fromUnixTime } from 'date-fns/esm';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Alert, Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AppContext } from '../../App';
import LikeButton from '../common/LikeButton';
import LikeButtonUnauth from '../common/LikeButtonUnauth';
import SocialMediaShareButton from '../common/shareButtons/SocialMediaShareButtons';
import VisitLinkButton from '../common/visitLinkButton/VisitLinkButton';
import {
  getTranslatedDescription,
  getTranslatedTitle,
} from '../search/helpers';

import './resourceDetails.scss';

const ResourceDetails = ({ toggle, hit }) => {
  const [
    {
      localeState: { upperCaseLocale, lowerCaseLocale },
      currentCognitoUser,
      userGroups,
    },
  ] = useContext(AppContext);

  const history = useHistory();

  const { t } = useTranslation();
  const [alertVis, setAlertVis] = useState(false);
  const onAlertDismiss = () => setAlertVis(false);

  return (
    <>
      <ModalHeader toggle={toggle}>
        {hit[getTranslatedTitle(upperCaseLocale, hit)]
          ? hit[getTranslatedTitle(upperCaseLocale, hit)]
          : hit.titleOthers[0]}
      </ModalHeader>

      <ModalBody>
        <div
          style={{
            display: 'flex',
            paddingBottom: '1rem',
            alignItems: 'center',
          }}
        >
          <VisitLinkButton href={hit.url} />
          <SocialMediaShareButton resource={hit} />
        </div>
        <div className="icon-desc-container">
          <div>
            <i className="fa fa-globe" />
          </div>
          <div>{hit[`language${upperCaseLocale}`]}</div>
        </div>
        {(hit[`${getTranslatedDescription(upperCaseLocale, hit)}`] ||
          hit.descriptionOthers[0]) && (
          <div className="icon-desc-container">
            <div>
              <i className="fa fa-info-circle" />
            </div>
            <div>
              {hit[`${getTranslatedDescription(upperCaseLocale, hit)}`]
                ? hit[`${getTranslatedDescription(upperCaseLocale, hit)}`]
                : hit.descriptionOthers[0]}
            </div>
          </div>
        )}
        <div className="icon-desc-container">
          <div>
            <i className="fa fa-link" />
          </div>
          <a href={hit.url} target="_blank" rel="noopener noreferrer">
            {hit.url}
          </a>
        </div>
        <div className="pre-footer" style={{ fontWeight: '600' }}>
          {hit[`mediaType${upperCaseLocale}`]}{' '}
          <>
            {hit[`contentType${upperCaseLocale}`] && (
              <> / {hit[`contentType${upperCaseLocale}`]}</>
            )}
          </>
        </div>
        <div>
          <div className="hit-footer-like-and-date">
            {currentCognitoUser ? (
              <LikeButton currentCognitoUser={currentCognitoUser} hit={hit} />
            ) : (
              <LikeButtonUnauth
                hit={hit}
                handleOnClick={() => setAlertVis(true)}
              />
            )}
            <div>
              {format(
                fromUnixTime(hit.approvedDateUnix),
                lowerCaseLocale === 'en' ? 'MM.dd.yyyy' : 'dd.MM.yyyy'
              )}
            </div>
          </div>
          <Alert
            color="danger"
            isOpen={alertVis}
            toggle={onAlertDismiss}
            style={{ marginTop: '1rem' }}
          >
            {t('Please sign-in')}{' '}
            <Link to="/user/login-profile">{t('Login')}</Link>
          </Alert>
        </div>
        {userGroups && userGroups.includes('APPROVERS' || 'ADMINS') && (
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                history.push(`/add-edit-resource/${hit.objectID}`);
              }}
            >
              {t('Edit')}
            </Button>{' '}
          </ModalFooter>
        )}
      </ModalBody>
    </>
  );
};

export default ResourceDetails;
