import React from 'react';

export default () => {
  return (
    <>
      <h1>Пользовательское соглашение</h1>
      <p>Дата последнего обновления: 15 Апреля, 2019г</p>
      <p>
        Данное Пользовательское соглашение регулирует отношения между вами и веб-сайтом Media Oasis (
        <a href="https://mediaoasis.org/">https://mediaoasis.org/</a>), находящийся в ведении Digital Nomads group.
      </p>
      <p>
        Прежде чем использовать наш Сервис, пожалуйста, внимательно ознакомьтесь с условиями настоящего
        Пользовательского соглашения.
      </p>
      <p>
        Ваш доступ к Сервису и его использование обусловлены тем, что вы принимаете и соблюдаете настоящие Условия.
        Данные Условия распространяются на всех посетителей, пользователей и других лиц, которые получают доступ или
        используют Сервис.
      </p>
      <p>
        Получая доступ к Сервису или используя его, вы соглашаетесь соблюдать настоящие Условия. Если вы не согласны с
        какой-либо частью условий, вы не можете получить доступ к Сервису.
      </p>
      <h2>Содержание</h2>
      <p>
        Наш Сервис позволяет публиковать, размещать ссылки и делиться и иным образом предоставлять определенную
        информацию, текст, графику, видео или другие материалы (&laquo;Контент&raquo;) на нашем веб-сайте. Вы несете
        ответственность за Контент, который вы публикуете на сайте, включая его законность, надежность и уместность.
      </p>
      <p>
        Размещая Контент на данном веб-сайте, вы предоставляете нам право и лицензию на использование, изменение,
        публичное отображение, воспроизведение и распространение вашего Контента на веб-сайте. Вы сохраняете все свои
        права на любой Контент, который вы отправляете, публикуете на веб-сайте или через него, и вы несете
        ответственность за защиту этих прав. Вы соглашаетесь с тем, что данная лицензия включает в себя право на то, что
        мы предоставляем доступ к Вашему Контенту другим пользователям сайта, которые в свою очередь могут использовать
        Ваш Контент в соответствии с настоящими Условиями.
      </p>
      <p>
        Вы заявляете и подтверждаете, что: (i) Контент принадлежит вам (вы являетесь его владельцем) или у вас есть
        право использовать его и предоставлять нам права и лицензии, предусмотренные настоящими Условиями, и (ii)
        размещение вашего Контента на сайте или через его Сервис не нарушает права на неприкосновенность частной жизни,
        права на гласность, авторские права, договорные права или любые другие права любого лица.
      </p>
      <h2>Учетная запись</h2>
      <p>
        Когда вы создаете учетную запись у нас, вы должны всегда предоставлять нам точную, полную и актуальную
        информацию. Невыполнение этого условия представляет собой нарушение Условий, которое может привести к
        немедленному закрытию вашей учетной записи в нашем сайте.
      </p>
      <p>
        Вы несете ответственность за защиту пароля, который вы используете для доступа к Сервису, а также за любую
        активность и действия, выполняемые под вашим паролем, независимо от того, используется ли ваш пароль на нашем
        сайте или сторонней службе на нашем сайте.
      </p>
      <p>
        Вы соглашаетесь не разглашать свой пароль третьим лицам. Вы должны немедленно уведомить нас о том, что вам стало
        известно о любом нарушении безопасности или несанкционированном использовании вашей учетной записи.
      </p>
      <p>
        Вы не можете использовать в качестве имени пользователя имя другого физического или юридического лица, которое
        не является законно доступным для использования, имя или товарный знак, которые принадлежат другому физическому
        или юридическому лицу, без соответствующего разрешения, а также не использовать имя, которое является
        оскорбительным, вульгарным или непристойным.
      </p>
      <h2>Ссылки на другие веб-сайты</h2>
      <p>
        Наш сайт может содержать ссылки на сторонние веб-сайты или сервисы, которые не принадлежат или не контролируются
        Media Oasis.
      </p>
      <p>
        Media Oasis не контролирует и не несет ответственности за содержание, политику конфиденциальности или действий
        любых сторонних веб-сайтов или служб. Вы также признаете и соглашаетесь с тем, что Media Oasis не несет
        ответственности, прямо или косвенно, за любой ущерб или убытки, вызванные или предположительно вызванные или
        связанные с использованием любого подобного контента сторонних веб-сайтов или служб, их товаров или услуг,
        доступные на или через любые такие веб-сайты или услуги.
      </p>
      <p>
        Мы настоятельно рекомендуем вам ознакомиться с условиями и политикой конфиденциальности любых сторонних
        веб-сайтов или служб, которые вы посещаете.
      </p>
      <h2>Расторжение</h2>
      <p>
        Мы можем немедленно прекратить или приостановить действие вашей учетной записи, без предварительного уведомления
        или ответственности, по любой причине, без ограничений, если вы нарушаете Условия.
      </p>
      <p>
        После прекращения действия вашей учетной записи, ваше право на использование нашего сервиса немедленно
        прекращается. Если вы хотите закрыть свою учетную запись, вы можете просто прекратить использование нашего
        сервиса.
      </p>
      <h2>Ограничение ответственности</h2>
      <p>
        Media Oasis, его директора, сотрудники, партнеры, агенты, поставщики или аффилированные лица ни в коем случае не
        несут ответственности за любые косвенные, случайные, специальные, косвенные или штрафные убытки, включая, помимо
        прочего упущенную выгоду, данные, гудвил или другие нематериальные убытки, возникшие в результате (i) вашего
        доступа или использования или невозможности доступа или использования Сервиса; (ii) любые действия или контент
        любой третьей стороны на нашем вебсайте; (iii) любой контент, полученный через Веб-сайт; и (iv)
        несанкционированный доступ, использование или изменение ваших передачи данных или контента, будь то на основе
        гарантии, контракта, деликта (включая халатность) или любой другой правовой теории, независимо от того, были ли
        мы проинформированы о возможности такого ущерба, и даже если установлено, что средство защиты, изложенное в
        настоящем документе, не достигло своей основной цели.
      </p>
      <h2>Отказ от ответственности</h2>
      <p>
        Использование настоящего сайта осуществляется пользователем на свой риск. Сервис доступен и распространяется по
        принципу "как есть" и "как доступно", без каких-либо гарантий, явных или подразумеваемых, включая, но не
        ограничиваясь, подразумеваемых гарантий товарности или пригодности для конкретной цели, или гарантий, вытекающих
        из использования или производительности.
      </p>
      <p>
        Media Oasis, дочерние компании, филиалы и лицензиары не гарантируют, что a{')'} Сервис будет работать
        бесперебойно, безопасно и доступно в любое конкретное время или в любом месте; б{')'} любые ошибки или дефекты
        будут исправлены; c{')'} Сервис не содержит вирусов или других вредных компонентов; или d{')'} результаты
        использования Сервиса будут соответствовать вашим требованиям.
      </p>
      <h2>Применимое законодательство</h2>
      <p>
        Настоящие Условия регулируются и толкуются в соответствии с законодательством Республики Казахстан, невзирая на
        его положения коллизионного права.
      </p>
      <p>
        Наша неспособность обеспечить какое-либо право или положение настоящих Условий не будет считаться отказом от
        этих прав. Если какое-либо положение настоящих Условий будет признано судом недействительным или не имеющим
        законной силы, остальные положения настоящих Условий останутся в силе. Настоящие Условия представляют собой
        полное соглашение между нами в отношении нашего Сервиса и заменяют собой любые прежние соглашения, которые могут
        заключаться между нами в отношении нашего Сервиса.
      </p>
      <h2>Изменения</h2>
      <p>
        Мы оставляем за собой право, по собственному усмотрению, изменять или заменять настоящие Условия в любое время.
        Если пересмотр является существенным, мы постараемся предоставить уведомление как минимум за 30 дней до
        вступления в силу новых условий, что представляет собой существенное изменение, будет определяться по нашему
        усмотрению.
      </p>
      <p>
        Продолжая получать доступ к нашему Сервису или использовать его после вступления в силу этих изменений, вы
        соглашаетесь соблюдать пересмотренные условия. Если вы не согласны с новыми условиями, пожалуйста, прекратите
        использование Сервиса.
      </p>
      <h2>Связь с нами</h2>
      <p>Свяжитесь с нами, в случае возникновения каких-либо вопросов об этих Условиях.</p>
    </>
  );
};
