import React from 'react';
import './widgets.scss';

const WidgetContainer = ({ children, title }) => {
  return (
    <div className="widget-container">
      <h4>{title}</h4>
      {children}
    </div>
  );
};

export default WidgetContainer;
