import React from "react";

export default () => {
  return (
    <>
      <h1>Утверждение Веры</h1>
      <p>
        Media Oasis (организация) привержена Евангельским Христианским
        убеждениям, как выражено в Заявлении Веры Всемирного Евангелического
        Альянса:
      </p>
      <p>Мы Верим:</p>
      <p>
        &hellip; В Священное Писание, боговдохновленное, непогрешимое, полностью
        заслуживающее доверия; и являющимся высшим авторитетом во всех вопросах
        веры и поведения.
      </p>
      <p>
        В единого Бога, вечно существующего в трех лицах: Отце, и Сыне, и Духе
        Святом.
      </p>
      <p>
        В Нашего Господа Иисуса Христа, Бога, явленного во плоти, в Его
        девственное рождение, в Его безгрешную человеческую жизнь, в Его
        божественные чудеса, в Его замещающую и искупительную смерть, в Его
        телесное воскресение, в Его вознесение, в Его посредническую работу и в
        Его личное возвращение в силе и славе.
      </p>
      <p>
        В спасение потерянного и грешного человечества через пролитую кровь
        Господа Иисуса Христа, дарованное по вере, не по делам, и возрождение
        через Духа Святого.
      </p>
      <p>
        В Святой Дух, пребывая в котором верующий может жить святой жизнью,
        свидетельствовать и трудиться для Господа Иисуса Христа.
      </p>
      <p>
        В то, что Церковь есть Тело Христово, а истинно верующие - члены этого
        Тела, объединенные одним духом.
      </p>
      <p>
        В воскресение как спасенных, так и потерянных; спасенные получат
        воскресение в вечную жизнь, потерянные будут приговорены на вечные
        мучения.
      </p>
      <p>
        Более подробное официальное утверждение веры, которое представляет
        религиозную позицию Media Oasis, можно найти в Посвящении из Кейптауна
        от 2010 года (по ссылке:{" "}
        <a href="https://www.lausanne.org/ru/content/ctc/posvjasenie-iz-kejptauna">
          https://www.lausanne.org/ru/content/ctc/posvjasenie-iz-kejptauna
        </a>
        ).
      </p>
      <p>
        Материалы, опубликованные на сайте Media Oasis и ссылки на другие
        ресурсы, должны соответствовать учениям, приведенным в утверждении веры.
        Редакционная коллегия Media Oasis оставляет за собой право удалять
        материалы, по которым имеются отступления от библейского, евангельского
        учения.
      </p>
      <p>Кроме того, редакция не будет ссылаться на:</p>
      <ol>
        <li>
          Любой материал, который имеет политический подтекст или содержит
          агитацию против правительства страны.
        </li>
        <li>Любой материал, который оскорбляет любые религиозные конфессии.</li>
        <li>
          Любой материал, который пропагандирует то, что известно под названием
          &laquo;Евангелием процветания&raquo; (более подробную информацию см.
          IIE Посвящения из Кейптауна.)
        </li>
      </ol>
    </>
  );
};
