import Amplify, { Auth, Hub } from 'aws-amplify';
import React, { lazy, useEffect, useMemo, useReducer } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { ReactQueryConfigProvider } from 'react-query';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import awsconfig from './aws-exports';
import AuthRoute from './components/common/AuthRoute';
import ContactUs from './components/contactUs/ContactUs';
import Layout from './components/layout/Layout';
import MineItems from './components/mineItems/MineItems';
import Page from './components/pages/Page';
import EditProfile from './components/profile/EditProfile';
import Profile from './components/profile/Profile';
import ResourceDetailsToShare from './components/resourceDetails/ResourceDetailsToShare';
import Search from './components/search/Search';

ReactGA.initialize('UA-114384436-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const AddEditResource = lazy(() =>
  import('./components/addEditResource/AddEditResource')
);
const Dashboard = lazy(() => import('./components/dashboard/Dashboard'));

Amplify.configure(awsconfig);

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    // suspense: true,
  },
};

export const AppContext = React.createContext();

export const initialState = {
  localeState: {
    upperCaseLocale: '',
    lowerCaseLocale: '',
    t: () => null,
  },
  currentCognitoUser: null,
};

export function appReducer(state, action) {
  switch (action.type) {
    case 'SET_LOCALE_STATE': {
      return {
        ...state,
        localeState: action.localeState,
      };
    }
    case 'SET_CURRENT_COGNITO_USER': {
      return {
        ...state,
        currentCognitoUser: action.currentCognitoUser,
        userGroups: action.currentCognitoUser
          ? action.currentCognitoUser.signInUserSession.accessToken.payload[
              'cognito:groups'
            ]
          : null,
      };
    }

    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
}

function App() {
  const [state, dispatch] = useReducer(appReducer, {
    ...initialState,
  });
  const { t, i18n } = useTranslation();
  const currentLocale =
    i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1, 2);

  const value = useMemo(() => [state, dispatch], [state]);

  useEffect(() => {
    dispatch({
      type: 'SET_LOCALE_STATE',
      localeState: {
        upperCaseLocale: currentLocale,
        lowerCaseLocale: currentLocale.toLowerCase(),
        t,
      },
    });
  }, [currentLocale]);

  const loadUser = async () => {
    let currentCognitoUser;

    try {
      currentCognitoUser = await Auth.currentAuthenticatedUser();
    } catch (e) {
      // eslint-disable-next-line no-console
      dispatch({
        type: 'SET_CURRENT_COGNITO_USER',
        currentCognitoUser: null,
      });
    }

    if (currentCognitoUser) {
      dispatch({
        type: 'SET_CURRENT_COGNITO_USER',
        currentCognitoUser,
      });
    } else {
      await Auth.currentCredentials();
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  Hub.listen('auth', _ => {
    loadUser();
  });

  return (
    <AppContext.Provider value={value}>
      <ReactQueryConfigProvider config={queryConfig}>
        <Router>
          <Switch>
            <Route exact path="/resource-details/:resourceId">
              <ResourceDetailsToShare />
            </Route>

            <Route exact path="/about-us">
              <Page pageType="About us" />
            </Route>
            <Route exact path="/terms-of-service">
              <Page pageType="Terms of Service" />
            </Route>
            <Route exact path="/statement-of-faith">
              <Page pageType="Our Beliefs" />
            </Route>
            <Route exact path="/contact-us">
              <ContactUs />
            </Route>
            <AuthRoute
              path="/add-edit-resource/:resourceId"
              component={AddEditResource}
            />
            <AuthRoute exact path="/user/login-profile" component={Profile} />
            <AuthRoute
              exact
              path="/user/edit-profile"
              component={EditProfile}
            />
            <AuthRoute exact path="/my-items" component={MineItems} />
            <AuthRoute path="/dashboard" component={Dashboard} />

            <Route path="/">
              <Layout subTitle={t('Search')}>
                <Search />
              </Layout>
            </Route>
          </Switch>
        </Router>
      </ReactQueryConfigProvider>
    </AppContext.Provider>
  );
}

export default App;
