export const myUsersByOwner = /* GraphQL */ `
  query MyUsersByOwner(
    $owner: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        email
        firstName
        lastName
        favoriteResources {
          items {
            id
            userID
            resourceID
            owner
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const myGetResource = /* GraphQL */ `
  query MyGetResource($id: ID!) {
    getResource(id: $id) {
      id
      url
      titles {
        langId
        title
      }
      descriptions {
        langId
        description
      }
      language {
        id
        enTranslation
        ruTranslation
        kkTranslation
      }
      mediaType {
        id
        enTranslation
        ruTranslation
        kkTranslation
      }
      contentType {
        id
        enTranslation
        ruTranslation
        kkTranslation
      }
      categories {
        items {
          id
          categoryID
          category {
            id
            enTranslation
            ruTranslation
            kkTranslation
          }
        }
        nextToken
      }
      bibleBookRefs {
        items {
          id
          bibleBookID
          chapterNumberRange
          bibleBook {
            id
            enTranslation
            ruTranslation
            kkTranslation
          }
        }
        nextToken
      }
      contributor {
        id
        owner
      }
      approver {
        id
        owner
      }
      approved
      missingLink
      inappropriate
      noteForApprover
      dateApproved
      upVote
      createdAt
      updatedAt
      favoritedByUsers {
        items {
          id
        }
      }
    }
  }
`;

export const myGetResourceNoApproved = /* GraphQL */ `
  query MyGetResourceNoApproved($id: ID!) {
    getResource(id: $id) {
      id
      url
      titles {
        langId
        title
      }
      descriptions {
        langId
        description
      }
      language {
        id
        enTranslation
        ruTranslation
        kkTranslation
      }
      mediaType {
        id
        enTranslation
        ruTranslation
        kkTranslation
      }
      contentType {
        id
        enTranslation
        ruTranslation
        kkTranslation
      }
      categories {
        items {
          id
          categoryID
          category {
            id
            enTranslation
            ruTranslation
            kkTranslation
          }
        }
        nextToken
      }
      bibleBookRefs {
        items {
          id
          bibleBookID
          chapterNumberRange
          bibleBook {
            id
            enTranslation
            ruTranslation
            kkTranslation
          }
        }
        nextToken
      }
      upVote
      createdAt
      updatedAt
    }
  }
`;

export const checkMetaInUseListResources = /* GraphQL */ `
  query MyCheckMetaInUseListResources(
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
    }
  }
`;

export const myGetCategory = /* GraphQL */ `
  query MyGetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      resources {
        items {
          id
        }
        nextToken
      }
    }
  }
`;

export const myListResourcesForDashboard = /* GraphQL */ `
  query MyListResourcesForDashboard(
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        url
        titles {
          langId
          title
        }
        createdAt
        updatedAt
        contributor {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        approved
      }
      nextToken
    }
  }
`;

export const myResourcesByContributorIdAndCreatedDate = /* GraphQL */ `
  query MyResourcesByContributorIdAndCreatedDate(
    $resourceContributorId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourcesByContributorIdAndCreatedDate(
      resourceContributorId: $resourceContributorId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        titles {
          langId
          title
        }
        descriptions {
          langId
          description
        }
        resourceLanguageId
        resourceMediaTypeId
        resourceContentTypeId
        resourceContributorId
        missingLink
        inappropriate
        noteForApprover
        dateApproved
        createdAt
        updatedAt
        sensitive
        expirationDate
        parentSite
        upView
        upVote
        contributor {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        approver {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        favoritedByUsers {
          nextToken
        }
        language {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        mediaType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        contentType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        bibleBookRefs {
          nextToken
        }
        approved
      }
      nextToken
    }
  }
`;

export const listOfLikedResourcesByUserIdAndCreatedAt = /* GraphQL */ `
  query ListOfLikedResourcesByUserIdAndCreatedAt(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOfLikedResourcesByUserIdAndCreatedAt(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        resourceID
        createdAt
        resource {
          id
          url
          titles {
            langId
            title
          }
          descriptions {
            langId
            description
          }
          mediaType {
            id
            enTranslation
            ruTranslation
            kkTranslation
          }
          contentType {
            id
            enTranslation
            ruTranslation
            kkTranslation
          }
          upVote
        }
      }
      nextToken
    }
  }
`;
