import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaginatedQuery, useQuery } from 'react-query';
import getLikedResources from '../../api/getLikedResources';
import { AppContext } from '../../App';
import UnlikeButton from '../common/UnlikeButton';
import LoadingComp from '../common/LoadingComp';
import SocialMediaShareButton from '../common/shareButtons/SocialMediaShareButtons';
import VisitLinkButton from '../common/visitLinkButton/VisitLinkButton';
import { getTranslatedAttrib } from '../resourceDetails/ResourceDetailsToShare';
import getCurrentUser from '../../api/getCurrentUser';

const initialState = {
  sortDirection: 'DESC',
  page: 1,
  limit: 10,
  nextToken: null,
};

const LikedResources = () => {
  const [
    {
      localeState: { upperCaseLocale, lowerCaseLocale },
      currentCognitoUser,
    },
  ] = useContext(AppContext);

  const { t } = useTranslation();

  // we keep it here for future use with pagination
  const [params, setParams] = useState({
    ...initialState,
  });

  const { isLoading: isCurrentUserLoading, data: currentUser } = useQuery(
    ['getCurrentUser', currentCognitoUser.username],
    getCurrentUser,
    { enabled: currentCognitoUser.username }
  );

  const { isLoading, resolvedData } = usePaginatedQuery(
    ['getLikedResources', { ...params, userID: currentUser && currentUser.id }],
    getLikedResources,
    {
      cacheTime: 0,
      enabled: currentUser,
    }
  );

  if (isCurrentUserLoading || isLoading) {
    return <LoadingComp style={{ marginTop: '-2rem' }} />;
  }

  if (resolvedData && resolvedData.items.length === 0) {
    return <div>{t('You have no resources in this category')}</div>;
  }

  return resolvedData.items.map(joinModel => {
    return (
      <div
        key={joinModel.resource.id}
        className="MineItems__resource-container"
      >
        <h4>
          {getTranslatedAttrib(
            joinModel.resource.titles,
            'title',
            upperCaseLocale
          )}
        </h4>

        {joinModel.resource.descriptions.length > 0 && (
          <p>
            {getTranslatedAttrib(
              joinModel.resource.descriptions,
              'description',
              upperCaseLocale
            )}
          </p>
        )}
        <div className="MineItems__pre-footer" style={{ fontWeight: '600' }}>
          {joinModel.resource.mediaType[`${lowerCaseLocale}Translation`]}{' '}
          <>
            {joinModel.resource.mediaType[`${lowerCaseLocale}Translation`]}{' '}
            <>
              {joinModel.resource.contentType && (
                <>
                  {' '}
                  /{' '}
                  {
                    joinModel.resource.contentType[
                      `${lowerCaseLocale}Translation`
                    ]
                  }
                </>
              )}
            </>
          </>
        </div>
        <div className="MineItems__footer">
          <div style={{ display: 'flex' }}>
            <VisitLinkButton href={joinModel.resource.url} />
            <SocialMediaShareButton resource={joinModel.resource} />
          </div>
          {/* <NavigateSiteButton to={`/resource-details/${joinModel.resource.id}`}>
            {t('Details')}
          </NavigateSiteButton> */}
          {/* <NavigateSiteButton
            to={`/add-edit-resource/${joinModel.resource.id}`}
          >
            {t('Edit')}
          </NavigateSiteButton> */}
          <div>
            <UnlikeButton currentUser={currentUser} hit={joinModel.resource} />
          </div>
        </div>
      </div>
    );
  });
};

export default LikedResources;
