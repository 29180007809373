import { API, graphqlOperation } from 'aws-amplify';

import * as myQueries from '../graphql/myQueries';

export default async function getCurrentUser(_, username) {
  const response = await API.graphql(
    graphqlOperation(myQueries.myUsersByOwner, {
      owner: username,
    })
  );
  return response.data.usersByOwner.items[0];
}
