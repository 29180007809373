import React, { useContext } from 'react';
import {
  connectSearchBox,
  Hits,
  Pagination,
  SortBy,
  Stats,
} from 'react-instantsearch-dom';
import { AppContext } from '../../App';
import Hit from './Hit';

const Results = connectSearchBox(() => {
  const [
    {
      localeState: { t },
    },
  ] = useContext(AppContext);

  return (
    <>
      <div className="info">
        {/* <Stats
          translations={{
            stats(n, ms) {
              return `${t('found')} ${n} ${t('in')} ${ms}ms`;
            },
          }}
        /> */}
        <div>
          <div>
            <div>
              <div>
                <SortBy
                  defaultRefinement="media_oasis_main_index"
                  items={[
                    {
                      value: 'media_oasis_main_index',
                      label: t('Most relevant'),
                    },
                    {
                      value: 'media_oasis_most_recent',
                      label: t('Most recent'),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="hits">
        <Hits
          hitComponent={hit => (
            <Hit hit={hit} windowSearch={window.location.search} />
          )}
        />
      </div>
      <div id="pagination" className="text-center">
        <Pagination />
      </div>
    </>
  );
});

export default Results;
