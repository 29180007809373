import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Highlight } from 'react-instantsearch-dom';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import getCurrentUser from '../../api/getCurrentUser';
import { AppContext } from '../../App';
import LikeButton from '../common/LikeButton';
import LikeButtonUnauth from '../common/LikeButtonUnauth';
import SocialMediaShareButton from '../common/shareButtons/SocialMediaShareButtons';
import ViewDetailsButton from '../common/viewDetailsButton/ViewDetailsButton';
import VisitLinkButton from '../common/visitLinkButton/VisitLinkButton';
import { getTranslatedDescription, getTranslatedTitle } from './helpers';

const Hit = ({ hit: { hit }, isWidget }) => {
  const [
    {
      localeState: { upperCaseLocale },
      currentCognitoUser,
    },
  ] = useContext(AppContext);

  const { data: currentUser } = useQuery(
    ['getCurrentUser', currentCognitoUser && currentCognitoUser.username],
    getCurrentUser,
    { enabled: currentCognitoUser }
  );

  const { t } = useTranslation();

  const [alertVis, setAlertVis] = useState(false);
  const onAlertDismiss = () => setAlertVis(false);

  return (
    <div className="hit row">
      <div className="hit-content">
        <ViewDetailsButton hit={hit}>
          <Highlight
            attribute={getTranslatedTitle(upperCaseLocale, hit)}
            hit={hit}
          />
        </ViewDetailsButton>

        <div className="hit-description">
          <Highlight
            attribute={getTranslatedDescription(upperCaseLocale, hit)}
            hit={hit}
          />
        </div>
        {!isWidget && (
          <>
            <div className="pre-footer">
              {hit[`mediaType${upperCaseLocale}`]}{' '}
              <>
                {hit.contentTypeEn && (
                  <> / {hit[`contentType${upperCaseLocale}`]}</>
                )}
              </>
            </div>

            <div className="hit-footer">
              <div>
                <VisitLinkButton href={hit.url} />
                <SocialMediaShareButton resource={hit} />
              </div>
              <div>
                {currentCognitoUser ? (
                  <LikeButton currentUser={currentUser} hit={hit} />
                ) : (
                  <LikeButtonUnauth
                    hit={hit}
                    handleOnClick={() => setAlertVis(true)}
                  />
                )}
              </div>
            </div>
            <Alert
              color="danger"
              isOpen={alertVis}
              toggle={onAlertDismiss}
              style={{ marginTop: '1rem' }}
            >
              {t('Please sign-in')}{' '}
              <Link to="/user/login-profile">{t('Login')}</Link>
            </Alert>
          </>
        )}
      </div>
    </div>
  );
};

export default Hit;
