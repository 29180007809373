import React from "react";

export default () => {
  return (
    <>
      <h1>Statement of Faith</h1>
      <p>
        Media Oasis is committed to an Evangelical Christian belief, as
        expressed in World Evangelical Alliance&rsquo;s{" "}
        <a href="http://www.worldea.org/whoweare/statementoffaith">
          Statement of Faith
        </a>
        :
      </p>
      <p>We believe</p>
      <p>
        ...in the Holy Scriptures as originally given by God, divinely inspired,
        infallible, entirely trustworthy; and the supreme authority in all
        matters of faith and conduct...
      </p>
      <p>
        One God, eternally existent in three persons, Father, Son, and Holy
        Spirit...
      </p>
      <p>
        Our Lord Jesus Christ, God manifest in the flesh, His virgin birth, His
        sinless human life, His divine miracles, His vicarious and atoning
        death, His bodily resurrection, His ascension, His mediatorial work, and
        His Personal return in power and glory...
      </p>
      <p>
        The Salvation of lost and sinful man through the shed blood of the Lord
        Jesus Christ by faith apart from works, and regeneration by the Holy
        Spirit...
      </p>
      <p>
        The Holy Spirit, by whose indwelling the believer is enabled to live a
        holy life, to witness and work for the Lord Jesus Christ...
      </p>
      <p>
        The Unity of the Spirit of all true believers, the Church, the Body of
        Christ...
      </p>
      <p>
        The Resurrection of both the saved and the lost; they that are saved
        unto the resurrection of life, they that are lost unto the resurrection
        of damnation.
      </p>
      <p>
        A more detailed confession that represents the faith position of Media
        Oasis can be found in the Cape Town Commitment of 2010:
      </p>
      <p>
        <a href="https://www.lausanne.org/content/covenant/lausanne-covenant">
          https://www.lausanne.org/content/ctc/ctcommitment
        </a>
      </p>
      <p>
        (Link to the Russian version:
        <a href="https://www.lausanne.org/ru/content/ctc/posvjasenie-iz-kejptauna">
          {" "}
          https://www.lausanne.org/ru/content/ctc/posvjasenie-iz-kejptauna
        </a>
        )
      </p>
      <p>
        Material linked to by the Media Oasis website should adhere to teachings
        that are in agreement with those expressed in these faith statements.
        The Media Oasis editorial team reserves the right to remove material
        that is found to diverge from biblical, evangelical teaching.
      </p>
      <p>In addition, the Media Oasis editorial team will not link to:</p>
      <p>
        1. Any material that is overtly political, attacking the governments of
        the region.
      </p>
      <p>2. Any material that attacks different faith confessions.</p>
      <p>
        3. Any material that promotes what is commonly called the 'prosperity
        gospel'. (For further information, see IIE.5 of the Cape Town
        Commitment).
      </p>
    </>
  );
};
