import classnames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import WidgetsIcon from '@material-ui/icons/Widgets';
import Favorite from '@material-ui/icons/Favorite';

import Layout from '../layout/Layout';
import LikedResources from './LikedResources';
import './mineItems.scss';
import UsersResources from './UserResources';

const MineItems = props => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Layout subTitle={t('My items')}>
      <Row>
        <Col sm={12} md={{ size: 6, offset: 3 }}>
          <h2>{t('My items')}</h2>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggle('1');
                }}
              >
                <Favorite /> {t('Liked')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2');
                }}
              >
                <WidgetsIcon /> {t('My resources')}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Container>
                  <LikedResources />
                </Container>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <Container>
                    <UsersResources />
                  </Container>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Layout>
  );
};

export default MineItems;
