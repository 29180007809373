/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const importDataIntoDb = /* GraphQL */ `
  mutation ImportDataIntoDb($typenameToImport: String!) {
    importDataIntoDb(typenameToImport: $typenameToImport)
  }
`;
export const addUpdateAlgoliaResource = /* GraphQL */ `
  mutation AddUpdateAlgoliaResource($input: AddUpdateAlgoliaRecordInput!) {
    addUpdateAlgoliaResource(input: $input) {
      res
      error
    }
  }
`;
export const deleteResourceFromAlgolia = /* GraphQL */ `
  mutation DeleteResourceFromAlgolia($id: String!) {
    deleteResourceFromAlgolia(id: $id) {
      res
      error
    }
  }
`;
export const upVoteAlgolia = /* GraphQL */ `
  mutation UpVoteAlgolia($id: String!, $newUpvoteScore: Int!) {
    upVoteAlgolia(id: $id, newUpvoteScore: $newUpvoteScore) {
      res
      error
    }
  }
`;
export const batchDeleteResourceCategory = /* GraphQL */ `
  mutation BatchDeleteResourceCategory($input: [BatchOperationInput!]!) {
    batchDeleteResourceCategory(input: $input) {
      res
      error
    }
  }
`;
export const batchDeleteResourceBibleBook = /* GraphQL */ `
  mutation BatchDeleteResourceBibleBook($input: [BatchOperationInput!]!) {
    batchDeleteResourceBibleBook(input: $input) {
      res
      error
    }
  }
`;
export const batchDeleteUserResource = /* GraphQL */ `
  mutation BatchDeleteUserResource($input: [BatchOperationInput!]!) {
    batchDeleteUserResource(input: $input) {
      res
      error
    }
  }
`;
export const contactUs = /* GraphQL */ `
  mutation ContactUs($name: String!, $email: String!, $message: String!) {
    contactUs(name: $name, email: $email, message: $message) {
      response
    }
  }
`;
export const contactUsAuth = /* GraphQL */ `
  mutation ContactUsAuth($name: String!, $email: String!, $message: String!) {
    contactUsAuth(name: $name, email: $email, message: $message) {
      response
    }
  }
`;
export const createUserResource = /* GraphQL */ `
  mutation CreateUserResource(
    $input: CreateUserResourceInput!
    $condition: ModelUserResourceConditionInput
  ) {
    createUserResource(input: $input, condition: $condition) {
      id
      userID
      resourceID
      owner
      createdAt
      updatedAt
      user {
        id
        owner
        email
        firstName
        lastName
        createdAt
        updatedAt
        favoriteResources {
          nextToken
        }
      }
      resource {
        id
        url
        titles {
          langId
          title
        }
        descriptions {
          langId
          description
        }
        resourceLanguageId
        resourceMediaTypeId
        resourceContentTypeId
        owner
        resourceContributorId
        missingLink
        inappropriate
        noteForApprover
        dateApproved
        createdAt
        updatedAt
        sensitive
        expirationDate
        parentSite
        upView
        upVote
        contributor {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        approver {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        favoritedByUsers {
          nextToken
        }
        language {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        mediaType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        contentType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        bibleBookRefs {
          nextToken
        }
        approved
      }
    }
  }
`;
export const updateUserResource = /* GraphQL */ `
  mutation UpdateUserResource(
    $input: UpdateUserResourceInput!
    $condition: ModelUserResourceConditionInput
  ) {
    updateUserResource(input: $input, condition: $condition) {
      id
      userID
      resourceID
      owner
      createdAt
      updatedAt
      user {
        id
        owner
        email
        firstName
        lastName
        createdAt
        updatedAt
        favoriteResources {
          nextToken
        }
      }
      resource {
        id
        url
        titles {
          langId
          title
        }
        descriptions {
          langId
          description
        }
        resourceLanguageId
        resourceMediaTypeId
        resourceContentTypeId
        owner
        resourceContributorId
        missingLink
        inappropriate
        noteForApprover
        dateApproved
        createdAt
        updatedAt
        sensitive
        expirationDate
        parentSite
        upView
        upVote
        contributor {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        approver {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        favoritedByUsers {
          nextToken
        }
        language {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        mediaType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        contentType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        bibleBookRefs {
          nextToken
        }
        approved
      }
    }
  }
`;
export const deleteUserResource = /* GraphQL */ `
  mutation DeleteUserResource(
    $input: DeleteUserResourceInput!
    $condition: ModelUserResourceConditionInput
  ) {
    deleteUserResource(input: $input, condition: $condition) {
      id
      userID
      resourceID
      owner
      createdAt
      updatedAt
      user {
        id
        owner
        email
        firstName
        lastName
        createdAt
        updatedAt
        favoriteResources {
          nextToken
        }
      }
      resource {
        id
        url
        titles {
          langId
          title
        }
        descriptions {
          langId
          description
        }
        resourceLanguageId
        resourceMediaTypeId
        resourceContentTypeId
        owner
        resourceContributorId
        missingLink
        inappropriate
        noteForApprover
        dateApproved
        createdAt
        updatedAt
        sensitive
        expirationDate
        parentSite
        upView
        upVote
        contributor {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        approver {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        favoritedByUsers {
          nextToken
        }
        language {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        mediaType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        contentType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        bibleBookRefs {
          nextToken
        }
        approved
      }
    }
  }
`;
export const createLanguage = /* GraphQL */ `
  mutation CreateLanguage(
    $input: CreateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    createLanguage(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
    }
  }
`;
export const updateLanguage = /* GraphQL */ `
  mutation UpdateLanguage(
    $input: UpdateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    updateLanguage(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
    }
  }
`;
export const deleteLanguage = /* GraphQL */ `
  mutation DeleteLanguage(
    $input: DeleteLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    deleteLanguage(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
    }
  }
`;
export const createMediaType = /* GraphQL */ `
  mutation CreateMediaType(
    $input: CreateMediaTypeInput!
    $condition: ModelMediaTypeConditionInput
  ) {
    createMediaType(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
    }
  }
`;
export const updateMediaType = /* GraphQL */ `
  mutation UpdateMediaType(
    $input: UpdateMediaTypeInput!
    $condition: ModelMediaTypeConditionInput
  ) {
    updateMediaType(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
    }
  }
`;
export const deleteMediaType = /* GraphQL */ `
  mutation DeleteMediaType(
    $input: DeleteMediaTypeInput!
    $condition: ModelMediaTypeConditionInput
  ) {
    deleteMediaType(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
    }
  }
`;
export const createContentType = /* GraphQL */ `
  mutation CreateContentType(
    $input: CreateContentTypeInput!
    $condition: ModelContentTypeConditionInput
  ) {
    createContentType(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
    }
  }
`;
export const updateContentType = /* GraphQL */ `
  mutation UpdateContentType(
    $input: UpdateContentTypeInput!
    $condition: ModelContentTypeConditionInput
  ) {
    updateContentType(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
    }
  }
`;
export const deleteContentType = /* GraphQL */ `
  mutation DeleteContentType(
    $input: DeleteContentTypeInput!
    $condition: ModelContentTypeConditionInput
  ) {
    deleteContentType(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
      resources {
        items {
          id
          resourceID
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
      resources {
        items {
          id
          resourceID
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      metaType
      createdAt
      updatedAt
      resources {
        items {
          id
          resourceID
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createResourceCategory = /* GraphQL */ `
  mutation CreateResourceCategory(
    $input: CreateResourceCategoryInput!
    $condition: ModelResourceCategoryConditionInput
  ) {
    createResourceCategory(input: $input, condition: $condition) {
      id
      resourceID
      categoryID
      createdAt
      updatedAt
      category {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
        resources {
          nextToken
        }
      }
      resource {
        id
        url
        titles {
          langId
          title
        }
        descriptions {
          langId
          description
        }
        resourceLanguageId
        resourceMediaTypeId
        resourceContentTypeId
        owner
        resourceContributorId
        missingLink
        inappropriate
        noteForApprover
        dateApproved
        createdAt
        updatedAt
        sensitive
        expirationDate
        parentSite
        upView
        upVote
        contributor {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        approver {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        favoritedByUsers {
          nextToken
        }
        language {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        mediaType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        contentType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        bibleBookRefs {
          nextToken
        }
        approved
      }
    }
  }
`;
export const updateResourceCategory = /* GraphQL */ `
  mutation UpdateResourceCategory(
    $input: UpdateResourceCategoryInput!
    $condition: ModelResourceCategoryConditionInput
  ) {
    updateResourceCategory(input: $input, condition: $condition) {
      id
      resourceID
      categoryID
      createdAt
      updatedAt
      category {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
        resources {
          nextToken
        }
      }
      resource {
        id
        url
        titles {
          langId
          title
        }
        descriptions {
          langId
          description
        }
        resourceLanguageId
        resourceMediaTypeId
        resourceContentTypeId
        owner
        resourceContributorId
        missingLink
        inappropriate
        noteForApprover
        dateApproved
        createdAt
        updatedAt
        sensitive
        expirationDate
        parentSite
        upView
        upVote
        contributor {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        approver {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        favoritedByUsers {
          nextToken
        }
        language {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        mediaType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        contentType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        bibleBookRefs {
          nextToken
        }
        approved
      }
    }
  }
`;
export const deleteResourceCategory = /* GraphQL */ `
  mutation DeleteResourceCategory(
    $input: DeleteResourceCategoryInput!
    $condition: ModelResourceCategoryConditionInput
  ) {
    deleteResourceCategory(input: $input, condition: $condition) {
      id
      resourceID
      categoryID
      createdAt
      updatedAt
      category {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
        resources {
          nextToken
        }
      }
      resource {
        id
        url
        titles {
          langId
          title
        }
        descriptions {
          langId
          description
        }
        resourceLanguageId
        resourceMediaTypeId
        resourceContentTypeId
        owner
        resourceContributorId
        missingLink
        inappropriate
        noteForApprover
        dateApproved
        createdAt
        updatedAt
        sensitive
        expirationDate
        parentSite
        upView
        upVote
        contributor {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        approver {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        favoritedByUsers {
          nextToken
        }
        language {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        mediaType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        contentType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        bibleBookRefs {
          nextToken
        }
        approved
      }
    }
  }
`;
export const createBibleBook = /* GraphQL */ `
  mutation CreateBibleBook(
    $input: CreateBibleBookInput!
    $condition: ModelBibleBookConditionInput
  ) {
    createBibleBook(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      isOt
      isNt
      createdAt
      updatedAt
      resources {
        items {
          id
          resourceID
          bibleBookID
          chapterNumberRange
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBibleBook = /* GraphQL */ `
  mutation UpdateBibleBook(
    $input: UpdateBibleBookInput!
    $condition: ModelBibleBookConditionInput
  ) {
    updateBibleBook(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      isOt
      isNt
      createdAt
      updatedAt
      resources {
        items {
          id
          resourceID
          bibleBookID
          chapterNumberRange
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBibleBook = /* GraphQL */ `
  mutation DeleteBibleBook(
    $input: DeleteBibleBookInput!
    $condition: ModelBibleBookConditionInput
  ) {
    deleteBibleBook(input: $input, condition: $condition) {
      id
      enTranslation
      ruTranslation
      kkTranslation
      isOt
      isNt
      createdAt
      updatedAt
      resources {
        items {
          id
          resourceID
          bibleBookID
          chapterNumberRange
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createResourceBibleBook = /* GraphQL */ `
  mutation CreateResourceBibleBook(
    $input: CreateResourceBibleBookInput!
    $condition: ModelResourceBibleBookConditionInput
  ) {
    createResourceBibleBook(input: $input, condition: $condition) {
      id
      resourceID
      bibleBookID
      chapterNumberRange
      createdAt
      updatedAt
      bibleBook {
        id
        enTranslation
        ruTranslation
        kkTranslation
        isOt
        isNt
        createdAt
        updatedAt
        resources {
          nextToken
        }
      }
      resource {
        id
        url
        titles {
          langId
          title
        }
        descriptions {
          langId
          description
        }
        resourceLanguageId
        resourceMediaTypeId
        resourceContentTypeId
        owner
        resourceContributorId
        missingLink
        inappropriate
        noteForApprover
        dateApproved
        createdAt
        updatedAt
        sensitive
        expirationDate
        parentSite
        upView
        upVote
        contributor {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        approver {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        favoritedByUsers {
          nextToken
        }
        language {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        mediaType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        contentType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        bibleBookRefs {
          nextToken
        }
        approved
      }
    }
  }
`;
export const updateResourceBibleBook = /* GraphQL */ `
  mutation UpdateResourceBibleBook(
    $input: UpdateResourceBibleBookInput!
    $condition: ModelResourceBibleBookConditionInput
  ) {
    updateResourceBibleBook(input: $input, condition: $condition) {
      id
      resourceID
      bibleBookID
      chapterNumberRange
      createdAt
      updatedAt
      bibleBook {
        id
        enTranslation
        ruTranslation
        kkTranslation
        isOt
        isNt
        createdAt
        updatedAt
        resources {
          nextToken
        }
      }
      resource {
        id
        url
        titles {
          langId
          title
        }
        descriptions {
          langId
          description
        }
        resourceLanguageId
        resourceMediaTypeId
        resourceContentTypeId
        owner
        resourceContributorId
        missingLink
        inappropriate
        noteForApprover
        dateApproved
        createdAt
        updatedAt
        sensitive
        expirationDate
        parentSite
        upView
        upVote
        contributor {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        approver {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        favoritedByUsers {
          nextToken
        }
        language {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        mediaType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        contentType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        bibleBookRefs {
          nextToken
        }
        approved
      }
    }
  }
`;
export const deleteResourceBibleBook = /* GraphQL */ `
  mutation DeleteResourceBibleBook(
    $input: DeleteResourceBibleBookInput!
    $condition: ModelResourceBibleBookConditionInput
  ) {
    deleteResourceBibleBook(input: $input, condition: $condition) {
      id
      resourceID
      bibleBookID
      chapterNumberRange
      createdAt
      updatedAt
      bibleBook {
        id
        enTranslation
        ruTranslation
        kkTranslation
        isOt
        isNt
        createdAt
        updatedAt
        resources {
          nextToken
        }
      }
      resource {
        id
        url
        titles {
          langId
          title
        }
        descriptions {
          langId
          description
        }
        resourceLanguageId
        resourceMediaTypeId
        resourceContentTypeId
        owner
        resourceContributorId
        missingLink
        inappropriate
        noteForApprover
        dateApproved
        createdAt
        updatedAt
        sensitive
        expirationDate
        parentSite
        upView
        upVote
        contributor {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        approver {
          id
          owner
          email
          firstName
          lastName
          createdAt
          updatedAt
        }
        favoritedByUsers {
          nextToken
        }
        language {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        mediaType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        contentType {
          id
          enTranslation
          ruTranslation
          kkTranslation
          metaType
          createdAt
          updatedAt
        }
        categories {
          nextToken
        }
        bibleBookRefs {
          nextToken
        }
        approved
      }
    }
  }
`;
export const createResource = /* GraphQL */ `
  mutation CreateResource(
    $input: CreateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    createResource(input: $input, condition: $condition) {
      id
      url
      titles {
        langId
        title
      }
      descriptions {
        langId
        description
      }
      resourceLanguageId
      resourceMediaTypeId
      resourceContentTypeId
      owner
      resourceContributorId
      missingLink
      inappropriate
      noteForApprover
      dateApproved
      createdAt
      updatedAt
      sensitive
      expirationDate
      parentSite
      upView
      upVote
      contributor {
        id
        owner
        email
        firstName
        lastName
        createdAt
        updatedAt
        favoriteResources {
          nextToken
        }
      }
      approver {
        id
        owner
        email
        firstName
        lastName
        createdAt
        updatedAt
        favoriteResources {
          nextToken
        }
      }
      favoritedByUsers {
        items {
          id
          userID
          resourceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      language {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
      }
      mediaType {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
      }
      contentType {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          resourceID
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      bibleBookRefs {
        items {
          id
          resourceID
          bibleBookID
          chapterNumberRange
          createdAt
          updatedAt
        }
        nextToken
      }
      approved
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource(
    $input: UpdateResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    updateResource(input: $input, condition: $condition) {
      id
      url
      titles {
        langId
        title
      }
      descriptions {
        langId
        description
      }
      resourceLanguageId
      resourceMediaTypeId
      resourceContentTypeId
      owner
      resourceContributorId
      missingLink
      inappropriate
      noteForApprover
      dateApproved
      createdAt
      updatedAt
      sensitive
      expirationDate
      parentSite
      upView
      upVote
      contributor {
        id
        owner
        email
        firstName
        lastName
        createdAt
        updatedAt
        favoriteResources {
          nextToken
        }
      }
      approver {
        id
        owner
        email
        firstName
        lastName
        createdAt
        updatedAt
        favoriteResources {
          nextToken
        }
      }
      favoritedByUsers {
        items {
          id
          userID
          resourceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      language {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
      }
      mediaType {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
      }
      contentType {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          resourceID
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      bibleBookRefs {
        items {
          id
          resourceID
          bibleBookID
          chapterNumberRange
          createdAt
          updatedAt
        }
        nextToken
      }
      approved
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource(
    $input: DeleteResourceInput!
    $condition: ModelResourceConditionInput
  ) {
    deleteResource(input: $input, condition: $condition) {
      id
      url
      titles {
        langId
        title
      }
      descriptions {
        langId
        description
      }
      resourceLanguageId
      resourceMediaTypeId
      resourceContentTypeId
      owner
      resourceContributorId
      missingLink
      inappropriate
      noteForApprover
      dateApproved
      createdAt
      updatedAt
      sensitive
      expirationDate
      parentSite
      upView
      upVote
      contributor {
        id
        owner
        email
        firstName
        lastName
        createdAt
        updatedAt
        favoriteResources {
          nextToken
        }
      }
      approver {
        id
        owner
        email
        firstName
        lastName
        createdAt
        updatedAt
        favoriteResources {
          nextToken
        }
      }
      favoritedByUsers {
        items {
          id
          userID
          resourceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      language {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
      }
      mediaType {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
      }
      contentType {
        id
        enTranslation
        ruTranslation
        kkTranslation
        metaType
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          resourceID
          categoryID
          createdAt
          updatedAt
        }
        nextToken
      }
      bibleBookRefs {
        items {
          id
          resourceID
          bibleBookID
          chapterNumberRange
          createdAt
          updatedAt
        }
        nextToken
      }
      approved
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      email
      firstName
      lastName
      createdAt
      updatedAt
      favoriteResources {
        items {
          id
          userID
          resourceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      email
      firstName
      lastName
      createdAt
      updatedAt
      favoriteResources {
        items {
          id
          userID
          resourceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      email
      firstName
      lastName
      createdAt
      updatedAt
      favoriteResources {
        items {
          id
          userID
          resourceID
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
