import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import * as myMutations from '../../graphql/myMutations';
import * as mutations from '../../graphql/mutations';
import LikeIcon from './LikeIcon';
import './likeButton.scss';
import { queryCache } from 'react-query';

const LikeButton = ({ hit, currentUser }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [hitStat, setHitStat] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (hit.upVote) {
      setHitStat(hit.upVote);
    } else {
      setHitStat(0);
    }
  }, []);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.favoriteResources.items.find(
        item => item.resourceID === hit.objectID
      )
    ) {
      setIsLiked(true);
    }
  }, [currentUser]);

  const handleOnClick = async () => {
    setIsSubmitting(true);
    if (!isLiked) {
      try {
        const promRes = await Promise.all([
          await API.graphql(
            graphqlOperation(myMutations.myCreateUserResource, {
              input: {
                userID: currentUser.id,
                resourceID: hit.objectID,
                owner: currentUser.id,
              },
            })
          ),
          await API.graphql(
            graphqlOperation(mutations.upVoteAlgolia, {
              id: hit.objectID,
              newUpvoteScore: hitStat ? hitStat + 1 : 1,
            })
          ),
        ]);
        if (promRes) {
          queryCache.invalidateQueries('getCurrentUser');
          setIsSubmitting(false);
          setIsLiked(true);
          setHitStat(hitStat + 1);
        }
      } catch (e) {
        setIsSubmitting(false);
        console.log(e);
      }
    } else {
      try {
        const promRes = await Promise.all([
          await API.graphql(
            graphqlOperation(myMutations.myDeleteUserResource, {
              input: {
                id: currentUser.favoriteResources.items.find(
                  item => item.resourceID === hit.objectID
                ).id,
              },
            })
          ),
          await API.graphql(
            graphqlOperation(mutations.upVoteAlgolia, {
              id: hit.objectID,
              newUpvoteScore: hitStat ? hitStat - 1 : 1,
            })
          ),
        ]);
        if (promRes) {
          setIsSubmitting(false);
          setIsLiked(false);
          setHitStat(hitStat - 1);
        }
      } catch (e) {
        setIsSubmitting(false);
        console.log(e);
      }
    }
  };

  return (
    <button type="button" className="like-button" onClick={handleOnClick}>
      <div className="like-button-inner-container">
        {isSubmitting && <i className="fa fa-spinner fa-pulse" />}
        <LikeIcon title="like" isLiked={isLiked} />{' '}
      </div>
      <span>{hitStat}</span>
    </button>
  );
};

export default LikeButton;
