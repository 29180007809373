import { Translations } from '@aws-amplify/ui-components';
import { I18n } from 'aws-amplify';
import {
  Authenticator,
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
} from 'aws-amplify-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import './authenticator.scss';
import CustomSignUp from './CustomSignUp';

export const myTheme = {
  container: {
    // maxWidth: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  // formContainer: {
  //   minWidth: '350px',
  // },
  toast: {
    backgroundColor: '#f8d7da',
    color: '#721c24',
    padding: '0.75rem 1.25rem',
    border: '1px solid #f5c6cb',
    borderRadius: '0.25rem',
    borderBottom: '1rem',
  },
  sectionHeader: {
    color: '#212529',
    fontSize: '1.5rem',
    marginBottom: '1rem',
  },
  inputLabel: {
    color: '#212529',
    marginBottom: '0.5rem',
  },
};

const AuthenticatorComp = () => {
  const { t, i18n } = useTranslation();

  I18n.putVocabulariesForLanguage(i18n.language, {
    [Translations.BACK_TO_SIGN_IN]: t('Back to Sign In'),
    [Translations.CHANGE_PASSWORD_ACTION]: t('Change'),
    [Translations.CHANGE_PASSWORD]: t('Change Password'),
    [Translations.CODE_LABEL]: t('Verification code'),
    [Translations.CODE_PLACEHOLDER]: t('Enter code'),
    [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: t('Confirmation Code'),
    [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: t('Enter your code'),
    [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: t('Confirm Sign up'),
    [Translations.CONFIRM_SIGN_UP_LOST_CODE]: t('Lost your code?'),
    [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: t('Resend Code'),
    [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: t('Confirm'),
    [Translations.CONFIRM_SMS_CODE]: t('Confirm SMS Code'),
    [Translations.CONFIRM_TOTP_CODE]: t('Confirm TOTP Code'),
    [Translations.CONFIRM]: t('Confirm'),
    [Translations.CREATE_ACCOUNT_TEXT]: t('Create account'),
    [Translations.EMAIL_LABEL]: t('Email Address *'),
    [Translations.EMAIL_PLACEHOLDER]: t('Enter your email address'),
    [Translations.FORGOT_PASSWORD_TEXT]: t('Forgot your password?'),
    [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]: t(
      'Less than two mfa types available'
    ),
    [Translations.NEW_PASSWORD_LABEL]: t('New password'),
    [Translations.NEW_PASSWORD_PLACEHOLDER]: t('Enter your new password'),
    [Translations.NO_ACCOUNT_TEXT]: t('No account?'),
    [Translations.PASSWORD_LABEL]: t('Password *'),
    [Translations.PASSWORD_PLACEHOLDER]: t('Enter your password'),
    [Translations.PHONE_LABEL]: t('Phone Number *'),
    [Translations.PHONE_PLACEHOLDER]: t('(555) 555-1212'),
    [Translations.QR_CODE_ALT]: t('qrcode'),
    [Translations.RESET_PASSWORD_TEXT]: t('Reset password'),
    [Translations.RESET_YOUR_PASSWORD]: t('Reset your password'),
    [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: t('Select MFA Type'),
    [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: t('Verify'),
    [Translations.SEND_CODE]: t('Send Code'),
    [Translations.SUBMIT]: t('Submit'),
    [Translations.SETUP_TOTP_REQUIRED]: t('TOTP needs to be configured'),
    [Translations.SIGN_IN_ACTION]: t('Sign In'),
    [Translations.SIGN_IN_HEADER_TEXT]: t('Sign in to your account'),
    [Translations.SIGN_IN_TEXT]: t('Sign in'),
    [Translations.SIGN_IN_WITH_AMAZON]: t('Sign in with Amazon'),
    [Translations.SIGN_IN_WITH_AUTH0]: t('Sign in with Auth0'),
    [Translations.SIGN_IN_WITH_AWS]: t('Sign in with AWS'),
    [Translations.SIGN_IN_WITH_FACEBOOK]: t('Sign in with Facebook'),
    [Translations.SIGN_IN_WITH_GOOGLE]: t('Sign in with Google'),
    [Translations.SIGN_OUT]: t('Sign Out'),
    [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: t('Email'),
    [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: t('Have an account?'),
    [Translations.SIGN_UP_HEADER_TEXT]: t('Create a new account'),
    [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: t('Password'),
    [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: t('Create Account'),
    [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: t('Username'),
    [Translations.SUCCESS_MFA_TYPE]: t('Success! Your MFA Type is now: '),
    [Translations.TOTP_HEADER_TEXT]: t('Scan then enter verification code'),
    [Translations.TOTP_LABEL]: t('Enter Security Code: '),
    [Translations.TOTP_ISSUER]: t('AWSCognito'),
    [Translations.TOTP_SETUP_FAILURE]: t('TOTP Setup has failed'),
    [Translations.TOTP_SUBMIT_BUTTON_TEXT]: t('Verify Security Token'),
    [Translations.TOTP_SUCCESS_MESSAGE]: t('Setup TOTP successfully!'),
    [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]: t(
      'Failed! Unable to configure MFA at this time'
    ),
    [Translations.USERNAME_LABEL]: t('Email *'),
    [Translations.USERNAME_PLACEHOLDER]: t('Enter your username'),
    [Translations.VERIFY_CONTACT_EMAIL_LABEL]: t('Email'),
    [Translations.VERIFY_CONTACT_HEADER_TEXT]: t(
      'Account recovery requires verified contact information'
    ),
    [Translations.VERIFY_CONTACT_PHONE_LABEL]: t('Phone Number'),
    [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: t('Submit'),
    [Translations.VERIFY_CONTACT_VERIFY_LABEL]: t('Verify'),
    [Translations.ADDRESS_LABEL]: t('Address'),
    [Translations.ADDRESS_PLACEHOLDER]: t('Enter your address'),
    [Translations.NICKNAME_LABEL]: t('Nickname'),
    [Translations.NICKNAME_PLACEHOLDER]: t('Enter your nickname'),
    [Translations.BIRTHDATE_LABEL]: t('Birthday'),
    [Translations.BIRTHDATE_PLACEHOLDER]: t('Enter your birthday'),
    [Translations.PICTURE_LABEL]: t('Picture URL'),
    [Translations.PICTURE_PLACEHOLDER]: t('Enter your picture URL'),
    [Translations.FAMILY_NAME_LABEL]: t('Family Name'),
    [Translations.FAMILY_NAME_PLACEHOLDER]: t('Enter your family name'),
    [Translations.PREFERRED_USERNAME_LABEL]: t('Preferred Username'),
    [Translations.PREFERRED_USERNAME_PLACEHOLDER]: t(
      'Enter your preferred username'
    ),
    [Translations.GENDER_LABEL]: t('Gender'),
    [Translations.GENDER_PLACEHOLDER]: t('Enter your gender'),
    [Translations.PROFILE_LABEL]: t('Profile URL'),
    [Translations.PROFILE_PLACEHOLDER]: t('Enter your profile URL'),
    [Translations.GIVEN_NAME_LABEL]: t('First Name'),
    [Translations.GIVEN_NAME_PLACEHOLDER]: t('Enter your first name'),
    [Translations.ZONEINFO_LABEL]: t('Time zone'),
    [Translations.ZONEINFO_PLACEHOLDER]: t('Enter your time zone'),
    [Translations.LOCALE_LABEL]: t('Locale'),
    [Translations.LOCALE_PLACEHOLDER]: t('Enter your locale'),
    [Translations.UPDATED_AT_LABEL]: t('Updated At'),
    [Translations.UPDATED_AT_PLACEHOLDER]: t(
      'Enter the time the information was last updated'
    ),
    [Translations.MIDDLE_NAME_LABEL]: t('Middle Name'),
    [Translations.MIDDLE_NAME_PLACEHOLDER]: t('Enter your middle name'),
    [Translations.WEBSITE_LABEL]: t('Website'),
    [Translations.WEBSITE_PLACEHOLDER]: t('Enter your website'),
    [Translations.NAME_LABEL]: t('Full Name'),
    [Translations.NAME_PLACEHOLDER]: t('Enter your full name'),
    [Translations.PHOTO_PICKER_TITLE]: t('Picker Title'),
    [Translations.PHOTO_PICKER_HINT]: t(
      'Ancilliary text or content may occupy this space here'
    ),
    [Translations.PHOTO_PICKER_PLACEHOLDER_HINT]: t('Placeholder hint'),
    [Translations.PHOTO_PICKER_BUTTON_TEXT]: t('Button'),
    [Translations.IMAGE_PICKER_TITLE]: t('Add Profile Photo'),
    [Translations.IMAGE_PICKER_HINT]: t('Preview the image before upload'),
    [Translations.IMAGE_PICKER_PLACEHOLDER_HINT]: t('Tap to image select'),
    [Translations.IMAGE_PICKER_BUTTON_TEXT]: t('Upload'),
    [Translations.PICKER_TEXT]: t('Pick a file'),
    [Translations.TEXT_FALLBACK_CONTENT]: t('Fallback Content'),
    [Translations.CONFIRM_SIGN_UP_FAILED]: t('Confirm Sign Up Failed'),
    [Translations.SIGN_UP_FAILED]: t('Sign Up Failed'),
  });

  const authScreenLabels = {
    en: {
      Username: 'Email',
      'Username cannot be empty': 'Email cannot be empty',
      'Forget your password? ': 'Forgot your password? ',
    },
    'en-US': {
      Username: 'Email',
      'Username cannot be empty': 'Email cannot be empty',
      'Forget your password? ': 'Forgot your password? ',
    },
    ru: {
      Username: 'Email',
      'Forget your password? ': 'Забыли Ваш пароль? ',
      'No account? ': 'Нет аккаунта? ',
      'Lost your code? ': 'Потеряли код? ',
      'Confirm Sign Up': 'Подтвердите регистрацию',
      'Username cannot be empty': 'Email не может быть пустым',
    },
    kk: {
      Username: 'Email',
      'Forget your password? ': 'Парольді ұмыттыңыз ба? ',
      'No account? ': 'Есептік жазба жоқ па? ',
      'Lost your code? ': 'Кодты жоғалтып алдыңыз ба? ',
      'Confirm Sign Up': 'Тіркелуді растаңыз',
      'Username cannot be empty': 'Email бос болмауы керек',
    },
  };

  I18n.putVocabularies(authScreenLabels);

  I18n.setLanguage(i18n.language);

  return (
    <Row>
      <Col>
        <div className="custom-auth">
          <Authenticator hideDefault={true} theme={myTheme}>
            <SignIn override={['SignUp']} />
            <CustomSignUp />
            <ConfirmSignIn />
            <ForgotPassword />
            <ConfirmSignIn />
            <ConfirmSignUp />
            <RequireNewPassword />
          </Authenticator>
        </div>
      </Col>
    </Row>
  );
};

export default AuthenticatorComp;
