import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import TextInput from '../common/TextInput';
import * as mutations from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import Layout from '../layout/Layout';
import { AppContext } from '../../App';
import { useHistory } from 'react-router-dom';

const ContactUs = () => {
  const [{ currentCognitoUser }] = useContext(AppContext);

  const history = useHistory();

  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);
  const [alertObj, setAlertObj] = useState(null);

  const handleSubmit = async (values, formTools) => {
    try {
      let response;

      if (currentCognitoUser) {
        response = await API.graphql(
          graphqlOperation(mutations.contactUsAuth, {
            ...values,
          })
        );
      } else {
        response = await API.graphql({
          query: mutations.contactUs,
          variables: values,
          authMode: 'AWS_IAM',
        });
      }

      if (response) {
        formTools.setSubmitting(false);
        formTools.resetForm();
        setAlertObj({
          type: 'success',
          message: `${t('Message has been sent')}`,
        });
        return;
      }
    } catch (e) {
      console.log(e);
      formTools.setSubmitting(false);
      setAlertObj({
        type: 'danger',
        message: `${t(
          'There was problem to send you message. Please contact us later'
        )}`,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('Required')),
    email: Yup.string()
      .email(t('Invalid email'))
      .required(t('Required')),
    message: Yup.string().required(t('Required')),
  });

  return (
    <Layout subTitle={t('Contact Us')}>
      <Row>
        <Col sm={12} md={{ size: 6, offset: 3 }}>
          <h2>{t('Contact Us')}</h2>

          {alertObj && (
            <Alert color={alertObj.type} isOpen={visible} toggle={onDismiss}>
              {alertObj.message}
            </Alert>
          )}
          <Formik
            initialValues={{
              name: '',
              email: '',
              message: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormGroup>
                  <Label htmlFor="name">{t('Name')}</Label>
                  <Field name="name" component={TextInput} />
                  <ErrorMessage
                    className="form-error"
                    component="div"
                    name="name"
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="email">{t('Email')}</Label>
                  <Field name="email" component={TextInput} />
                  <ErrorMessage
                    className="form-error"
                    component="div"
                    name="email"
                  />
                </FormGroup>
                <FormGroup style={{ marginBottom: '1rem' }}>
                  <Label htmlFor="message">{t('Message')}</Label>
                  <Field
                    name="message"
                    as="textarea"
                    className="form-control"
                  />
                  <ErrorMessage
                    className="form-error"
                    component="div"
                    name="message"
                  />
                </FormGroup>

                <Row style={{ marginBottom: '1rem' }}>
                  <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      color="secondary"
                      onClick={() => history.goBack()}
                      style={{ marginRight: '1rem' }}
                    >
                      {t('Cancel')}
                    </Button>

                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && <i className="fa fa-spinner fa-pulse" />}{' '}
                      {t('Submit')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Layout>
  );
};

export default ContactUs;
