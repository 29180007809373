import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { AppContext } from '../../App';
import MostLiked from '../widgets/MostLiked';
import MostRecent from '../widgets/MostRecent';
import Facets from './Facets';
import MediaIconFilter from './MediaIconFilter';
import Results from './Results';
import './search.scss';

const Search = () => {
  const [
    {
      localeState: { upperCaseLocale },
    },
  ] = useContext(AppContext);

  return (
    <Row>
      <Col md="12" className="media-icon-filter">
        <MediaIconFilter attribute={`mediaType${upperCaseLocale}`} />
      </Col>
      <Col md="3">
        <Facets />
      </Col>
      <Col md="6" className="result-column">
        <Results />
      </Col>
      <Col md="3">
        <MostLiked />
        <MostRecent />
      </Col>
    </Row>
  );
};

export default Search;
