import React, { useContext, useState } from 'react';
import { AppContext } from '../../App';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import Layout from '../layout/Layout';
import NavigateSiteButton from '../common/navigateSiteButton/NavigateSiteButton';
import getCurrentUser from '../../api/getCurrentUser';
import { useQuery } from 'react-query';
import LoadingComp from '../common/LoadingComp';

import { Auth, API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const Profile = () => {
  const [{ currentCognitoUser, userGroups }, dispatch] = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [isRemovedAccountConfirmed, setIsRemovedAccountConfirmed] = useState(
    false
  );

  const { isLoading, data: currentUser } = useQuery(
    ['getCurrentUser', currentCognitoUser.username],
    getCurrentUser,
    { enabled: currentCognitoUser.username }
  );

  const deleteUser = async () => {
    if (!isRemovedAccountConfirmed) {
      setIsRemovedAccountConfirmed(true);
    } else {
      const apiName = 'AdminQueries';
      const path = '/deleteUser';
      const myInit = {
        body: {
          username: currentCognitoUser.username,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
        },
      };

      const res = await API.post(apiName, path, myInit);

      if (res) {
        dispatch({
          type: 'SET_CURRENT_COGNITO_USER',
          currentCognitoUser: null,
        });
        localStorage.clear();
        history.push('/');
      }
    }
  };

  if (isLoading) {
    return <LoadingComp style={{ marginTop: '-2rem' }} />;
  }

  return (
    <Layout subTitle={t('Profile')}>
      <Row>
        <Col sm={12} md={{ size: 6, offset: 3 }}>
          <h2>{t('Profile')}</h2>
          <p>
            <strong>Email:</strong> {currentUser.email}
          </p>
          <p>
            <strong>{t('First Name')}</strong>: {currentUser.firstName}
          </p>
          <p>
            <strong>{t('Last Name')}</strong>: {currentUser.lastName}
          </p>
          <p>
            <strong>{t('Groups')}</strong>:{' '}
            {userGroups.map((group, idx) => (
              <React.Fragment key={idx}>
                <span key={idx}>{group}</span>
                {userGroups[idx + 1] && <>, </>}
              </React.Fragment>
            ))}
          </p>
          <Button
            type="button"
            onClick={() => deleteUser(currentCognitoUser.username)}
            color="danger"
            className="visit-link-button"
          >
            {!isRemovedAccountConfirmed ? t('Delete my account') : t('Confirm')}
          </Button>
          {isRemovedAccountConfirmed && (
            <Button
              type="button"
              onClick={() => setIsRemovedAccountConfirmed(false)}
              color="secondary"
              className="visit-link-button"
            >
              {t('Cancel')}
            </Button>
          )}
          <NavigateSiteButton to="/user/edit-profile">
            {t('Edit profile')}
          </NavigateSiteButton>
        </Col>
      </Row>
    </Layout>
  );
};

export default Profile;
