import { Auth } from 'aws-amplify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Row from 'reactstrap/lib/Row';
import * as Yup from 'yup';
import TextInput from '../common/TextInput';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { myTheme } from './Authenticator';

const TermsOfServicesLink = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Link to="/terms-of-service">{t('terms_of_services_link')}</Link>
    </div>
  );
};

const CustomSignUp = ({ onStateChange, authState }) => {
  if (authState !== 'signUp') {
    return null;
  }

  const { t } = useTranslation();

  const signUp = async ({ email, password }, formTools) => {
    try {
      const user = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      });
      if (user) {
        onStateChange('confirmSignUp', user);
      }
    } catch (error) {
      if (error.code === 'UsernameExistsException') {
        formTools.setFieldError('email', 'user already exists');
      }
    }
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('Invalid email'))
      .required(t('Required')),
    password: Yup.string()
      .required(t('Required'))
      .matches(
        /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
        t(
          'password must contain 6 symbols, one uppercase, and one special case character'
        )
      ),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t('Passwords must match'))
      .required(t('Required')),
    termsOfServices: Yup.bool().oneOf([true], t('Required')),
  });

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        passwordConfirmation: '',
        termsOfServices: true,
      }}
      validationSchema={SignupSchema}
      onSubmit={(values, formTools) => signUp(values, formTools)}
    >
      <Form>
        <div style={{ ...myTheme.sectionHeader }}>{t('Create account')}</div>
        <FormGroup>
          <Label
            htmlFor="email"
            style={{ ...myTheme.inputLabel, fontWeight: 'normal' }}
          >
            {t('Email')}
          </Label>
          <Field name="email" component={TextInput} />
          <ErrorMessage className="form-error" component="div" name="email" />
        </FormGroup>
        <FormGroup>
          <Label
            htmlFor="password"
            style={{ ...myTheme.inputLabel, fontWeight: 'normal' }}
          >
            {t('Password')}
          </Label>
          <Field name="password" type="password" component={TextInput} />
          <ErrorMessage
            className="form-error"
            component="div"
            name="password"
          />
        </FormGroup>
        <FormGroup>
          <Label
            htmlFor="password"
            style={{ ...myTheme.inputLabel, fontWeight: 'normal' }}
          >
            {t('Confirm Password')}
          </Label>
          <Field
            name="passwordConfirmation"
            type="password"
            component={TextInput}
          />
          <ErrorMessage
            className="form-error"
            component="div"
            name="passwordConfirmation"
          />
        </FormGroup>
        <FormGroup check style={{ marginBottom: '1rem' }}>
          <Label
            htmlFor="termsOfServices"
            style={{ ...myTheme.inputLabel, fontWeight: 'normal' }}
            check
          >
            <Field
              name="termsOfServices"
              component={TextInput}
              type="checkbox"
            />
            {t('agree_with_terms')}
          </Label>

          <ErrorMessage
            className="form-error"
            component="div"
            name="termsOfServices"
          />
          <TermsOfServicesLink />
        </FormGroup>

        <Row style={{ marginBottom: '1rem' }}>
          <Col>
            <Button color="link" onClick={() => onStateChange('signIn')}>
              {t('Sign In')}
            </Button>
          </Col>
          <Col>
            <Button color="link" onClick={() => onStateChange('confirmSignUp')}>
              {t('Have a code?')}
            </Button>
          </Col>
        </Row>

        <Button type="submit" color="primary" size="lg" block>
          {t('Create account')}
        </Button>
      </Form>
    </Formik>
  );
};

export default CustomSignUp;
