import React from 'react';
import { searchClient } from '../layout/Layout';
import { InstantSearch, Hits, Configure } from 'react-instantsearch-dom';
import Hit from '../search/Hit';
import WidgetContainer from './WidgetContainer';
import { useTranslation } from 'react-i18next';

const MostRecent = () => {
  const { t } = useTranslation();
  return (
    <WidgetContainer title={t('Most recent')}>
      <InstantSearch
        searchClient={searchClient}
        indexName="media_oasis_most_recent"
      >
        <Configure hitsPerPage={2} />
        <Hits hitComponent={hit => <Hit hit={hit} isWidget={true} />} />
      </InstantSearch>
    </WidgetContainer>
  );
};

export default MostRecent;
