import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import './custom-btsp.scss';
import './i18n';
import LoadingComp from './components/common/LoadingComp';
import * as serviceWorker from './serviceWorker';

// loading component for suspense fallback
const Loader = () => <LoadingComp />;

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <App />
  </Suspense>,
  document.getElementById('root')
);

serviceWorker.register();
