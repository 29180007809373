import algoliasearch from 'algoliasearch/lite';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import CarouselComp from '../carousel/CarouselComp';
import Search from '../search/Search';
import NavbarComponent from './NavbarComponent';

export const searchClient = algoliasearch(
  'XY2I4EYBNN',
  '9735a603c34b8cfd1c7a8cb12198b7a2'
);

const DEBOUNCE_TIME = 700;

const createURL = state => {
  return state ? `?${qs.stringify(state)}` : '';
};

const searchStateToUrl = searchState =>
  searchState ? `/${createURL(searchState)}` : '';

const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

function Layout({ children, subTitle }) {
  const location = useLocation();
  const history = useHistory();
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const setStateId = useRef();

  useEffect(() => {
    const nextSearchState = urlToSearchState(location);

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }

    // eslint-disable-next-line
  }, [location]);

  const onSearchStateChange = nextSearchState => {
    clearTimeout(setStateId.current);

    if (location) {
      setStateId.current = setTimeout(() => {
        history.push(searchStateToUrl(nextSearchState), nextSearchState);
      }, DEBOUNCE_TIME);
    }

    setSearchState(nextSearchState);
  };

  return (
    <>
      <Helmet>
        <title>{`Media Oasis | ${subTitle}`}</title>
      </Helmet>
      <InstantSearch
        searchClient={searchClient}
        indexName="media_oasis_main_index"
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <Configure hitsPerPage={10} />
        <div className="navbar-container">
          <NavbarComponent searchState={searchState} />
        </div>
        <div className="main">
          {location.pathname === '/' && <CarouselComp />}
          <Container style={{ marginTop: '2rem' }}>
            {searchState.query ? <Search /> : children}
          </Container>
        </div>
        <footer className="footer">
          <Container>
            <Row>
              <Col>
                <div className="credits ml-auto" style={{ float: 'right' }}>
                  <span className="copyright">
                    &copy; {new Date().getFullYear()}{' '}
                    <i className="fa fa-heart" style={{ color: 'red' }} /> Media
                    Oasis
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </InstantSearch>
    </>
  );
}

export default Layout;
